import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel, Select } from '@mui/material';
import InstagramIcon from '../images/instagram.svg';
import MessengerIcon from '../images/messenger.svg';
import WhatsappIcon from '../images/whatsapp.webp';
import WebIcon from '../images/web.webp';

const FilterOptions = ({ filters, onFilterChange, sources }) => {
  const handleFromDateChange = (event) => {
    onFilterChange({ ...filters, from: event.target.value });
  };

  const handleToDateChange = (event) => {
    onFilterChange({ ...filters, to: event.target.value });
  };

  const handleSourceChange = (event) => {
    onFilterChange({ ...filters, source: event.target.value });
  };

  const handleFeedbackChange = (event) => {
    onFilterChange({ ...filters, feedback: event.target.value });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const iconStyle = { marginRight: '0.6rem', width: '1rem', height: '1rem' };

  return (
    <Box p={2}>
      <TextField
        label="From Date"
        type="datetime-local"
        value={filters.from}
        onChange={handleFromDateChange}
        margin="normal"
        InputLabel={{
          shrink: true,
        }}
        sx={{
          backgroundColor: 'white'
        }}
      />
      <TextField
        label="To Date"
        type="datetime-local"
        value={filters.to}
        onChange={handleToDateChange}
        margin="normal"
        InputLabel={{
          shrink: true,
        }}
        sx={{
          backgroundColor: 'white'
        }}
      />
      <FormControl fullWidth margin="normal" sx={{ backgroundColor: 'white' }}>
        <InputLabel>Source</InputLabel>
        <Select value={filters.source} onChange={handleSourceChange} label="Source">
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value="web">
            <img src={WebIcon} alt="Web" style={iconStyle} />
            Web
          </MenuItem>
          <MenuItem value="facebook">
            <img src={MessengerIcon} alt="Facebook" style={iconStyle} />
            Facebook
          </MenuItem>
          <MenuItem value="instagram">
            <img src={InstagramIcon} alt="Instagram" style={iconStyle} />
            Instagram
          </MenuItem>
          <MenuItem value="whatsapp">
            <img src={WhatsappIcon} alt="Whatsapp" style={iconStyle} />
            Whatsapp
          </MenuItem>
        </Select>
      </FormControl>

      {/* New Feedback Filter */}
      <FormControl fullWidth margin="normal" sx={{ backgroundColor: 'white' }}>
        <InputLabel>Feedback</InputLabel>
        <Select value={filters.feedback} onChange={handleFeedbackChange} label="Feedback">
          <MenuItem value="">
            <em>All</em>
          </MenuItem>
          <MenuItem value="positive">Positive</MenuItem>
          <MenuItem value="negative">Negative</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterOptions;
