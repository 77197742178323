import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { toast } from 'react-toastify';
import axios from 'axios';
import loaderGif from '../images/loader.gif'; // Add the path to your loader gif

const FacebookLoginComponent = ({ details = [] }) => {
  const sessionInfoResponseRef = useRef(null);
  const sdkResponseRef = useRef(null);
  const navigate = useNavigate();
  const [phoneNumberId, setPhoneNumberId] = useState("");
  const [wabaId, setWabaId] = useState("");

  const wabaIdRef = useRef("");

  const [channels, setChannels] = useState([]);
  const { id } = useParams(); // Extract chatbotId from URL parameters
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Array.isArray(details)) {
      const channels = details
        .filter(detail => detail.type === 'whatsapp')
        .map(detail => ({
          chatbot_id: detail.chatbot_id || '',
          channel_id: detail.channel_id,
          whatsapp_account_id: detail.whatsapp_account_id || '',
          whatsapp_status: detail.whatsapp_status || '',
          whatsapp_phone_number_id: detail.whatsapp_phone_number_id || ''
        }));

      setChannels(channels);
    }
  }, [details]);
  // Load Facebook SDK and attach event listener on mount
  useEffect(() => {
    // Define message listener for the window
    const messageListener = (event) => {
      if (
        event.origin !== 'https://www.facebook.com' &&
        event.origin !== 'https://web.facebook.com'
      ) {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        console.log('Received data:', data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH_WHATSAPP_BUSINESS_APP_ONBOARDING') {
            const { phone_number_id, waba_id } = data.data;
            console.log(
              'Phone number ID',
              phone_number_id,
              'WhatsApp business account ID',
              waba_id
            );
            setPhoneNumberId(phone_number_id);
            setWabaId(waba_id);
            wabaIdRef.current = waba_id;

            // if user cancels the Embedded Signup flow
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn('Cancel at', current_step);
            // if user reports an error during the Embedded Signup flow
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error('Error:', error_message);
          }
        }
        if (sessionInfoResponseRef.current) {
          sessionInfoResponseRef.current.textContent = JSON.stringify(
            data,
            null,
            2
          );
        }
      } catch {
        console.log('Non JSON Responses', event.data);
      }
    };

    // Attach the message event listener
    // Dynamically load the Facebook SDK script
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.onload = () => {
      // Initialise the Facebook SDK
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '3729084597409698', // update with your app ID
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v22.0',
        });
      };
      window.addEventListener('message', messageListener);

    }

    document.body.appendChild(script);

    // Cleanup the event listener and remove script on unmount
    return () => {
      window.removeEventListener('message', messageListener);
      document.body.removeChild(script);
    };
  }, [navigate]);

  const handleWhatsappOAuthCode = (code) => {
    setIsLoading(true);

    const token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_API_URL}/api/updateWhatsappToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ code: code, chatbot_id: id, phone_number_id: phoneNumberId, waba_id: wabaIdRef.current,
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          const whatsappDetailsArray = Array.isArray(data.facebook_details)
          ? data.facebook_details
          : [data.facebook_details];
          const channels = whatsappDetailsArray.map(facebookDetails => ({
            chatbot_id: id,
            channel_id: facebookDetails.channel_id,
            whatsapp_phone_number_id: facebookDetails.whatsapp_phone_number_id, // corrected key
            whatsapp_account_id: facebookDetails.whatsapp_account_id,
            whatsapp_status: facebookDetails.whatsapp_status,
          }));
          setChannels(channels);
        } else {
          throw new Error('Failed to update Whatsapp token');
        }
      })
      .catch(error => {
        console.error(`Error updating Whatsapp token: ${error.message}`);
        toast.error(`Error updating Whatsapp token: ${error.message}`, { position: "top-center" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleConnectDisconnect = async (whatsapp_account_id, action) => {
    const token = localStorage.getItem('access_token');
    const updateChannelStatus = async () => {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/whatsapp_${action}`,
        {
          page_id: whatsapp_account_id,
          chatbot_id: id
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Update the channel status locally
      setChannels(channels.map(channel =>
        channel.whatsapp_account_id === whatsapp_account_id
          ? { ...channel, whatsapp_status: action === 'connect' ? 'Connected' : 'Disconnected' }
          : channel
      ));
    };

    toast.promise(
      updateChannelStatus,
      {
        pending: `Processing ${action}...`,
        success: `${action.charAt(0).toUpperCase() + action.slice(1)}ed successfully!`,
        error: `Error ${action}ing source`
      },
      { position: "top-center" }

    ).catch(error => {
      console.error(`Error ${action}ing source:`, error);
    });
  };

  // Callback function for Facebook login
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const fbCode = response.authResponse.code;
      //console.log(fbCode)
      handleWhatsappOAuthCode(fbCode);
      // The returned code must be transmitted to your backend first and then
      // perform a server-to-server call from there to our servers for an access token.
    }
    if (sdkResponseRef.current) {
      sdkResponseRef.current.textContent = JSON.stringify(response, null, 2);
    }
  };

  // Launch the WhatsApp signup process using Facebook login
  const launchWhatsAppSignup = () => {
    if (window.FB && window.FB.login) {
      window.FB.login(fbLoginCallback, {
        config_id: '1107644901112011', // configuration ID goes here
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
        extras: {
          setup: {},
          featureType: 'whatsapp_business_app_onboarding',
          sessionInfoVersion: '3',
        },
      });
    } else {
      console.error('FB SDK not loaded.');
    }
  };

  const renderChannels = () => {
    return channels.map((channel, index) => (
      <ListItem key={index} style={{
        display: 'flex', alignItems: 'center', padding: '0'
      }}>
        <Box width={'40%'} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '1rem' }} >
          {channel.whatsapp_account_id && (
            <>
              <ListItemText primary={`${channel.whatsapp_account_id} `} sx={{ padding: '0' }} /> {/* - Status: ${channel.facebookStatus}`} /> */}
            </>
          )}
        </Box>
        <Box width={'60%'}>
          {channel.whatsapp_status === 'Connected' ? (
            <Button variant="contained" color="warning" onClick={() => handleConnectDisconnect(channel.whatsapp_account_id, 'disconnect')} style={{ marginLeft: '10px', width: '8rem' }}>Disconnect</Button>
          ) : (
            <Button variant="contained" onClick={() => handleConnectDisconnect(channel.whatsapp_account_id, 'connect')} style={{ marginLeft: '10px', width: '8rem' }}>Connect</Button>
          )}
        </Box>
      </ListItem>
    ));
  };


  return (
    <div>
      <List sx={{ paddingTop: '0', paddingBottom: '0' }}>
        {renderChannels()}
      </List>
      {channels.length === 0 && (
                <>
      <div id="fb-root"></div>

      <Button
        variant="contained"
        onClick={launchWhatsAppSignup}

      >
        Log in to Whatsapp via Facebook (BETA)
      </Button>

      {isLoading && (
        <img src={loaderGif} alt="Loading" className="loading-gif" />
      )}
      </>
      )};
    </div>
     
  );
};

export default FacebookLoginComponent;
