import React, { useEffect, useState , useRef} from 'react';
import { Button } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import loaderGif from '../images/loader.gif'; // Add the path to your loader gif
import { toast } from 'react-toastify';

const ConnectGoogleCalendar = () => {
  // Your provided client ID
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Extract chatbotId from URL parameters
  const [isLoading, setIsLoading] = useState(false);
  const hasHandledCode = useRef(false); // flag to ensure one-time execution

  const clientId = '433910433094-msa3bsjdgkp1v244hdqrhl18g624bam9.apps.googleusercontent.com';
  // Replace with your actual redirect URI which will handle the OAuth callback
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_URL}`);
  // Scope for accessing the user's calendar
  const scope = encodeURIComponent('https://www.googleapis.com/auth/calendar');
  const responseType = 'code';
  // Optional state parameter for CSRF protection
  const state = encodeURIComponent('custom_state_value');
  // Additional parameters to request offline access (for refresh tokens) and to ensure a consent prompt
  const accessType = 'offline';
  const includeGrantedScopes = 'true';
  const prompt = 'consent';

  // Construct the Google OAuth URL
  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=${accessType}&include_granted_scopes=${includeGrantedScopes}&prompt=${prompt}&state=${state}`;

  const handleConnect = () => {
    // Redirect the user to Google OAuth screen
    window.location.href = googleAuthUrl;

  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state')
    if (state != 'microsoft' && code && !hasHandledCode.current) {
      hasHandledCode.current = true;
      handleGoogleOAuthCode(code);
      // Remove code parameter from URL after handling it
      urlParams.delete('code');
      navigate({ search: urlParams.toString() }, { replace: true });
    }
  }, [location, navigate]);


  const handleGoogleOAuthCode = (googleCode) => {
    setIsLoading(true);
    const loadingToastId  = toast.loading("Connecting to Google Calendar", { position: "top-center" });

    const token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_API_URL}/api/googleOauth2callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ code: googleCode, chatbot_id: id }),
    })
      .then(response => response.json())
      .then(data => {
        toast.dismiss(loadingToastId);

        if (data.success) {
          console.log('success');
          toast.success('Google Calendar Connected', { position: "top-center" });

        } else {
          toast.error('Error - Google Calendar Not Connected', { position: "top-center" });
          throw new Error('Failed to update Google token');

        }
      })
      .catch(error => {
        console.error(`Error updating Google token: ${error.message}`);
        toast.error(`Error updating Google token: ${error.message}`, { position: "top-center" });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  return (
    <>
      {isLoading && (
        <img src={loaderGif} alt="Loading" className="loading-gif" />
      )}
      <Button variant="contained" color="primary" onClick={handleConnect}>
        Connect to Google Calendar
      </Button>
    </>

  );
};

export default ConnectGoogleCalendar;
