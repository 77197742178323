import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Switch,
  CircularProgress,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMinus,
  faComments,
  faSyncAlt,
  faTimes,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';
import 'react-quill/dist/quill.snow.css';
import DOMPurify from 'dompurify';
import '../ChatWidget.css'; // Importing CSS
import ColorPicker from './ColorPicker'; // Import the ColorPicker component
import axios from 'axios';
import { toast } from 'react-toastify';
import RemoveIcon from '@mui/icons-material/Remove';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Cropper from 'react-easy-crop'; // Import Cropper for image cropping
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';

const ChatLayoutTab = () => {
  const { id } = useParams();

  // Existing state declarations...
  const details = localStorage.getItem('aissie-chatbot-details-' + id)
    ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
    : [];
  const layout = details.style ? details.style : '';
  const [chatbotId, setChatbotId] = useState(details.id);
  const [initialMessages, setInitialMessages] = useState(details.initial_messages);
  const [fallbackMessage, setFallbackMessage] = useState(details.fallback_message);
  const [suggestedMessages, setSuggestedMessages] = useState(details.suggested_messages || []);
  const [messagePlaceholder, setMessagePlaceholder] = useState(layout.messagePlaceholder);
  const [footer, setFooter] = useState(layout.footer);
  const [backgroundColor, setBackgroundColor] = useState(layout.backgroundColor);
  const [botFontColor, setBotFontColor] = useState(layout.botFontColor);
  const [userFontColor, setUserFontColor] = useState(layout.userFontColor);
  const [userBackgroundColor, setUserBackgroundColor] = useState(layout.userBackgroundColor);
  const [botBackgroundColor, setBotBackgroundColor] = useState(layout.botBackgroundColor);
  const [ThemeBackgroundColor, setThemeBackgroundColor] = useState(layout.ThemeBackgroundColor);
  const [ThemeFontColor, setThemeFontColor] = useState(layout.ThemeFontColor);
  const [displayName, setDisplayName] = useState(layout.displayName);
  const [sendLabel, setSendLabel] = useState(layout.sendLabel);
  const [feedbackActive, setFeedbackActive] = useState(details.feedback_active || false);
  const [showInputBox, setShowInputBox] = useState(layout.showInputBox || false);
  const [widgetPosition, setWidgetPosition] = useState(layout.position || "right");


  const showFeedbackIcons = feedbackActive;
  const [feedbackMessage, setFeedbackMessage] = useState(details.feedback_message);
  const [callMessage, setCallMessage] = useState(details.call_message);
  const [botProfilePicture, setBotProfilePicture] = useState(
    layout.chatbot_icon ? 'https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + layout.chatbot_icon : null
  );
  const [fileId, setFileId] = useState('');
  const [chatIconID, setChatIconID] = useState(layout.chatbot_icon ? layout.chatbot_icon : null);
  const messagesEndRef = useRef(null);

  // New state variables for saving status
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModified, setIsModified] = useState(false);

  // New state variables for cropping dialog and raw image file
  const [openCropDialog, setOpenCropDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rawImage, setRawImage] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  // Helper functions for cropping
  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.setAttribute('crossOrigin', 'anonymous');
      image.src = url;
    });
    const widgetPositionStyle = {
      ...(widgetPosition === 'center' && { bottom: '20px',left: '50%', transform: 'translateX(-50%)', right: 'unset' }),
      ...(widgetPosition === 'left' && {bottom: '20px', left: '20px', right: 'unset' }),
      ...(widgetPosition === 'right' && { bottom: '20px', right: '20px', left: 'unset' }),
    };
    
  const getCroppedImg = async (imageSrc, pixelCrop) => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error('Canvas is empty');
          return reject(new Error('Canvas is empty'));
        }
        blob.name = 'cropped.jpeg';
        resolve(blob);
      }, 'image/jpeg');
    });
  };

  // Function to save state to local storage
  const saveStateToLocalStorage = () => {
    console.log('save state function called');

    const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};

    const newState = {
      id: chatbotId,
      initial_messages: initialMessages,
      fallback_message: fallbackMessage,
      feedback_active: feedbackActive,
      feedback_message: feedbackMessage,
      call_message: callMessage,
      suggested_messages: suggestedMessages,
      style: {
        messagePlaceholder,
        showInputBox,
        position: widgetPosition,
        footer,
        backgroundColor,
        botFontColor,
        userFontColor,
        userBackgroundColor,
        botBackgroundColor,
        ThemeBackgroundColor,
        ThemeFontColor,
        displayName,
        botProfilePicture,
        sendLabel,
        chatbot_icon: chatIconID
      }
    };

    const updatedState = {
      ...existingState,
      ...newState,
      style: {
        ...existingState.style,
        ...newState.style
      }
    };

    localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
  };

  useEffect(() => {
    const localStorageDetails = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id));

    if ((JSON.stringify(details) !== JSON.stringify(localStorageDetails)) && !isModified) {
      const detailsLayout = details.style ? details.style : '';
      setChatbotId(details.id);
      setInitialMessages(details.initial_messages);
      setFeedbackActive(details.feedback_active);
      setFeedbackMessage(details.feedback_message);
      setFallbackMessage(details.fallback_message);
      setCallMessage(details.call_message);
      setSuggestedMessages(details.suggested_messages);
      setMessagePlaceholder(detailsLayout.messagePlaceholder);
      setFooter(detailsLayout.footer);
      setBackgroundColor(detailsLayout.backgroundColor);
      setBotFontColor(detailsLayout.botFontColor);
      setUserFontColor(detailsLayout.userFontColor);
      setUserBackgroundColor(detailsLayout.userBackgroundColor);
      setBotBackgroundColor(detailsLayout.botBackgroundColor);
      setThemeBackgroundColor(detailsLayout.ThemeBackgroundColor);
      setThemeFontColor(detailsLayout.ThemeFontColor);
      setDisplayName(detailsLayout.displayName);
      setSendLabel(detailsLayout.sendLabel);
      setBotProfilePicture('https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + detailsLayout.chatbot_icon);
      setShowInputBox(detailsLayout.showInputBox);
      setWidgetPosition(detailsLayout.position);
    }
  }, [details]);

  useEffect(() => {
    if (isModified) {
      saveStateToLocalStorage();
    }
  }, [isSaving]);

  useEffect(() => {
    const details = localStorage.getItem('aissie-chatbot-details-' + id)
      ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
      : {};

    const layout = details.style || {};

    setChatbotId(details.id || null);
    setInitialMessages(details.initial_messages || '');
    setFeedbackActive(details.feedback_active || false);
    setFeedbackMessage(details.feedback_message || '');
    setCallMessage(details.call_message || '');
    setFallbackMessage(details.fallback_message || '');
    setSuggestedMessages(details.suggested_messages || []);
    setMessagePlaceholder(layout.messagePlaceholder || '');
    setShowInputBox(layout.showInputBox || false);
    setWidgetPosition(layout.position || 'right');
    setFooter(layout.footer || '');
    setBackgroundColor(layout.backgroundColor || '');
    setBotFontColor(layout.botFontColor || '');
    setUserFontColor(layout.userFontColor || '');
    setUserBackgroundColor(layout.userBackgroundColor || '');
    setBotBackgroundColor(layout.botBackgroundColor || '');
    setThemeBackgroundColor(layout.ThemeBackgroundColor || '');
    setThemeFontColor(layout.ThemeFontColor || '');
    setDisplayName(layout.displayName || '');
    setSendLabel(layout.sendLabel || '');
    setBotProfilePicture(
      layout.chatbot_icon
        ? 'https://aissieragfiles.blob.core.windows.net/chatbot-icons/' + layout.chatbot_icon
        : null
    );
    setChatIconID(layout.chatbot_icon || null);
  }, [id]);

  // Updated: Open crop dialog on file select and store original file
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml') && file.size <= 1048576) {
      const imageUrl = URL.createObjectURL(file);
      setRawImage(file);
      setSelectedImage(imageUrl);
      setOpenCropDialog(true);
    } else {
      alert('File must be JPG, PNG, or SVG and less than 1MB');
    }
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCropConfirm = async () => {
    try {
      const croppedImageBlob = await getCroppedImg(selectedImage, croppedAreaPixels);
      const croppedFile = new File([croppedImageBlob], "cropped.jpeg", { type: "image/jpeg" });
      const formData = new FormData();
      formData.append('file', croppedFile);
      formData.append('chatbot_id', chatbotId);
      formData.append('type', 'icon');
      const token = localStorage.getItem('access_token');

      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: token,
        },
      });
      const newFileName = uploadResponse.data[0].filename;
      // Append a timestamp to avoid caching issues
      setBotProfilePicture(`https://aissieragfiles.blob.core.windows.net/chatbot-icons/${newFileName}?t=${new Date().getTime()}`);
      setChatIconID(newFileName);
      setFileId(newFileName);
      toast.success('File uploaded successfully!', { position: "top-center" });
    } catch (error) {
      toast.error('Failed to upload file.', { position: "top-center" });
      console.error('Error cropping/uploading image:', error);
    } finally {
      setOpenCropDialog(false);
      // Note: We retain rawImage so the user can re-open the crop if needed.
    }
  };

  const handleCropCancel = () => {
    setOpenCropDialog(false);
    setSelectedImage(null);
  };

  // New: Function to re-open crop dialog using the original image file
  const handleReCrop = () => {
    if (rawImage) {
      setSelectedImage(URL.createObjectURL(rawImage));
      setOpenCropDialog(true);
    }
  };

  const handleSaveClick = async () => {
    setIsSaving(true);
    const token = localStorage.getItem('access_token');
    const layout = {
      messagePlaceholder,
      footer,
      backgroundColor,
      botFontColor,
      userFontColor,
      userBackgroundColor,
      botBackgroundColor,
      ThemeBackgroundColor,
      ThemeFontColor,
      displayName,
      sendLabel,
      chatbot_icon: chatIconID,
      position: widgetPosition,
      showInputBox,
    };

    const saveChatbot = async () => {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
        initial_messages: initialMessages,
        feedback_active: feedbackActive,
        feedback_message: feedbackMessage,
        call_message: callMessage,
        fallback_message: fallbackMessage,
        suggested_messages: suggestedMessages,
        style: layout
      }, {
        headers: {
          Authorization: token,
        },
      });
    };

    toast.promise(
      saveChatbot(),
      {
        pending: 'Saving AI assistant...',
        success: 'AI assistant saved successfully!',
        error: 'Failed to save AI assistant.'
      }, {
      position: "top-center"
    }
    ).catch(error => {
      console.error('Error saving AI assistant:', error);
    }).finally(() => {
      setIsSaving(false);
      setIsModified(true);
    });
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...suggestedMessages];
    updatedQuestions[index] = value;
    setSuggestedMessages(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setSuggestedMessages([...suggestedMessages, '']);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestions = suggestedMessages.filter((_, i) => i !== index);
    setSuggestedMessages(updatedQuestions);
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, []);

  const inlineFooter = footer ? ` - ${footer.replace(/<p>/g, '').replace(/<\/p>/g, '')}` : '';

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box display="flex" style={{ maxWidth: "60vw" }} gap={4}>
      {/* Cropping dialog */}
      {openCropDialog && (
        <Dialog open={openCropDialog} onClose={handleCropCancel} maxWidth="sm" fullWidth>
          <DialogTitle>Crop Profile Picture</DialogTitle>
          <DialogContent>
            <div style={{ position: 'relative', width: '100%', height: 400, background: '#333' }}>
              <Cropper
                image={selectedImage}
                crop={crop}
                zoom={zoom}
                cropShape="round" // Use a circular crop mask
                aspect={1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <Typography variant="caption">Zoom</Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCropCancel}>Cancel</Button>
            <Button onClick={handleCropConfirm} variant="contained" color="primary">Crop &amp; Save</Button>
          </DialogActions>
        </Dialog>
      )}

      <Box flex={1} component="form" onSubmit={(event) => { event.preventDefault(); handleSaveClick(); }} >
        <Typography variant="h4" fontWeight="700" padding="1rem 0">Chat Layout</Typography>
        <Typography fontWeight="400" marginBottom={"1rem"}>You can change the appearance of the AI assistant to match your website's colour and theme.</Typography>

        <Paper square={false} elevation={4} style={{ padding: "1rem 3rem", width: '80%' }}>
          <Typography variant="h6" mt={2}>Display Name</Typography>
          <TextField
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6">Welcome Message</Typography>
          <TextField
            value={initialMessages}
            onChange={(e) => setInitialMessages(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6">Fallback Message (when the AI Assistant is unable to answer)</Typography>
          <TextField
            value={fallbackMessage}
            onChange={(e) => setFallbackMessage(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6" sx={{ marginBottom: '0.1rem' }} mt={2}>Suggested Questions</Typography>
          <Typography sx={{ marginBottom: '0.2rem' }} mt={2}>
            You can add suggested questions that allow users to simply click on a question instead of typing it out. This makes it easier for users to quickly select predefined questions, and you can customise these suggestions to suit your needs.
          </Typography>
          {suggestedMessages && (
            <>
              {suggestedMessages.map((question, index) => (
                <Box key={index} display="flex" alignItems="center" mb={2}>
                  <TextField
                    required
                    value={question}
                    onChange={(e) => handleQuestionChange(index, e.target.value)}
                    variant="outlined"
                    placeholder="What service do you provide?"
                    fullWidth
                    sx={{ backgroundColor: 'white', marginRight: '8px' }}
                  />
                  <IconButton onClick={() => handleRemoveQuestion(index)} >
                    <RemoveIcon />
                  </IconButton>
                </Box>
              ))}
              <Button variant="outlined" color="primary" onClick={handleAddQuestion}>
                Add suggested questions
              </Button>
            </>
          )}
          <Typography variant="h6" mt={2}>Message Placeholder</Typography>
          <TextField
            value={messagePlaceholder}
            onChange={(e) => setMessagePlaceholder(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6" mt={2}>Send Button Label</Typography>
          <TextField
            value={sendLabel}
            onChange={(e) => setSendLabel(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6" mt={2}>Feedback</Typography>
          <Box mt={2} display={"flex"}>
            <Typography variant="subtitle1">Active</Typography>
            <Switch checked={feedbackActive} onChange={(e) => setFeedbackActive(e.target.checked)} />
            <Typography variant="subtitle1">Feedback Message</Typography>
            <TextField
              multiline
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ backgroundColor: 'white' }}
            />
          </Box>
          <Typography variant="h6" mt={2}>Show Text Input While Minimised</Typography>
          <Switch checked={showInputBox} onChange={(e) => setShowInputBox(e.target.checked)} />
          <Typography variant="h6" mt={2}>Widget Position While Minimised</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id="widget-position-label">Position</InputLabel>
            <Select
              labelId="widget-position-label"
              id="widget-position"
              value={widgetPosition}
              label="Position"
              onChange={(e) => setWidgetPosition(e.target.value)}
            >
              <MenuItem value="left">Left</MenuItem>
              <MenuItem value="center">Center</MenuItem>
              <MenuItem value="right">Right</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="h6" mt={2}>Chat Invitation</Typography>
          <TextField
            value={callMessage}
            onChange={(e) => setCallMessage(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{ backgroundColor: 'white' }}
          />
          <Typography variant="h6" mt={2}>Header &amp; Buttons Background Colour</Typography>
          <ColorPicker
            defaultColor={ThemeBackgroundColor}
            onChangeComplete={(color) => setThemeBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Header &amp; Buttons Font Colour</Typography>
          <ColorPicker
            defaultColor={ThemeFontColor}
            onChangeComplete={(color) => setThemeFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Background Colour</Typography>
          <ColorPicker
            defaultColor={backgroundColor}
            onChangeComplete={(color) => setBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Font Colour</Typography>
          <ColorPicker
            defaultColor={botFontColor}
            onChangeComplete={(color) => setBotFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>User Font Colour</Typography>
          <ColorPicker
            defaultColor={userFontColor}
            onChangeComplete={(color) => setUserFontColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Background Colour</Typography>
          <ColorPicker
            defaultColor={botBackgroundColor}
            onChangeComplete={(color) => setBotBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>User Background Colour</Typography>
          <ColorPicker
            defaultColor={userBackgroundColor}
            onChangeComplete={(color) => setUserBackgroundColor(color.hex)}
          />
          <Typography variant="h6" mt={2}>Bot Profile Picture</Typography>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          {botProfilePicture && (
            <Box mt={1}>
<img src={botProfilePicture} alt="Bot" style={{ width: '2.8rem', height: '2.6rem', borderRadius: '50%', float: 'left', marginRight: '5px' }} />
{rawImage && (
                <Button variant="outlined" onClick={handleReCrop} sx={{ mt: 1 }}>
                  Re-crop Image
                </Button>
              )}
            </Box>
          )}
          <Button type="submit" variant="contained" color="primary" disabled={isSaving} sx={{ mt: 2 }}>
            {isSaving ? <CircularProgress size={24} /> : 'Save'}
          </Button>
        </Paper>
      </Box>
      <Box flex={1} p={2} className="chat-widget-preview-box">
        <Paper square={false} elevation={4} style={{ padding: "0.5rem", width: '100%', height: "100%" }}>
          <Typography variant="h6">Chat Interface Preview</Typography>
          <div id="chat-widget-preview" style={{ width: '100%', height: '90%', display: 'flex', flexDirection: 'column', fontFamily: 'Poppins, sans-serif' }}>
            <div id="chat-header-preview" style={{ backgroundColor: ThemeBackgroundColor, color: ThemeFontColor, padding: '12px', borderRadius: '15px 15px 0 0', textAlign: 'center', fontWeight: '600' }}>
              <div className="header-content-preview">
                <span className="minimise-text-preview">{displayName}</span>
                <FontAwesomeIcon icon={faMinus} className="minimise-icon-preview" title='Minimise Chat' />
                <FontAwesomeIcon icon={faSyncAlt} className="refresh-icon-preview" title='Refresh Chat' />
              </div>
            </div>
            <div id="chat-body-preview" style={{ flex: 1, overflowY: 'auto', padding: '0.8rem', backgroundColor, overflowX: "hidden" }}>
              <div className="message assistant" style={{ backgroundColor: botBackgroundColor, marginTop: '20px', whiteSpace: 'pre-wrap', color: botFontColor }}>
                {botProfilePicture && (<img src={botProfilePicture} alt="Bot" className="bot-icon" style={{ width: '40px', height: '40px', borderRadius: '50%', verticalAlign: 'middle', marginRight: '10px' }} />)}
                {initialMessages && (<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(initialMessages.replace(/\n/g, '<br>')) }} />)}
                {showFeedbackIcons && (
                  <div className={"feedbackIcons"} style={{ cursor: 'default' }}>
                    <FontAwesomeIcon icon={faThumbsUp} title="Thumbs Up" className={"feedbackIcon"} style={{ cursor: 'pointer' }} />
                    <FontAwesomeIcon icon={faThumbsDown} title="Thumbs Down" className={"feedbackIcon"} style={{ cursor: 'pointer' }} />
                  </div>
                )}
              </div>
              <div className="message user" style={{ backgroundColor: userBackgroundColor, marginTop: '20px', whiteSpace: 'pre-wrap', color: userFontColor }}>
                <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize("Hi!") }} />
              </div>
              <div ref={messagesEndRef} />
              {suggestedMessages && suggestedMessages.length > 0 && (
                <div id="suggested-questions-preview">
                  <div className="suggestions-header-preview">
                    <FontAwesomeIcon icon={faTimes} className="close-icon-preview" />
                  </div>
                  <div className="suggestions-container-preview">
                    {suggestedMessages.map((question, index) => (
                      <Button
                        variant='outlined'
                        key={index}
                        sx={{
                          backgroundColor: ThemeBackgroundColor, color: ThemeFontColor, textTransform: 'none',
                          '&:hover': {
                            opacity: 0.8,
                            backgroundColor: ThemeBackgroundColor
                          }
                        }}
                        endIcon={<ChevronRightIcon />}
                      >
                        {question}
                      </Button>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div id="chat-footer-preview" style={{ display: 'flex', padding: '10px', backgroundColor: '#f5f5f5' }}>
              <textarea
                id="chat-input-preview"
                placeholder={messagePlaceholder}
                style={{
                  flex: 1,
                  padding: '10px',
                  marginRight: '10px',
                  border: '1px solid #ccc',
                  borderRadius: '20px',
                  outline: 'none',
                  resize: 'none',
                  overflow: 'hidden',
                  minHeight: '20px',
                  maxHeight: '100px',
                  background: "white"
                }}
                rows={1}
                disabled
              />
              <button
                id="send-button-preview"
                style={{
                  backgroundColor: ThemeBackgroundColor,
                  color: ThemeFontColor,
                  border: 'none',
                  padding: '10px 15px',
                  cursor: 'pointer',
                  borderRadius: '20px',
                  fontFamily: 'Poppins, sans-serif',
                  fontWeight: '600'
                }}
                disabled
              >
                {sendLabel}
              </button>
            </div>
            <div id="footer-preview" style={{ textAlign: 'center', padding: '10px', fontSize: '0.875rem', color: '#555', backgroundColor: '#f5f5f5', borderTop: '1px solid #e0e0e0' }}>
              Powered by <a href="https://www.aissie.com.au" target="_blank" rel="noopener noreferrer">AIssie</a>
            </div>
           
          </div>
          
        </Paper>
        
      </Box>
      {showInputBox ? (
              <div id="chat-footer-minimised" className={'chatFooterMinimised'} style={widgetPositionStyle}>
                <SearchIcon sx={{ color: ThemeBackgroundColor }} />
                <TextField
                  id="chat-input"
                  className={"chat-input"}
                  placeholder={messagePlaceholder}
                  variant="outlined"
                  fullWidth
                  multiline
                  InputProps={{
                    sx: {
                      padding: '0px',
                      fontWeight: 600,
                      border: 'none !important',
                      borderStyle: 'none !important',
                      '&:focus': { border: 'none', borderStyle: 'none !important' },
                    },
                  }}
                  sx={{
                    backgroundColor: 'white',
                    lineHeight: '100% !important',
                    height: '100% !important',
                    padding: 'unset !important',
                    fontSize: '16px !important',
                    marginRight: '10px !important',
                    '&:active, &:focus': {
                      borderStyle: 'none !important',
                      border: 'none !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': { border: 'none' },
                      '&:hover fieldset': { borderColor: 'unset' },
                      '&.Mui-focused fieldset': {
                        borderColor: ThemeBackgroundColor,
                      },
                    },
                    '& .MuiOutlinedInput-input:focus': {
                      border: 'none !important',
                      outline: 'none !important',
                      borderStyle: 'none !important',
                    },
                  }}
                />

                <Button
                  id="send-button-minimised"
                  className={'send-button'}
                  sx={{
                    backgroundColor: `${ThemeBackgroundColor} !important`,
                    color: ThemeFontColor,
                    borderRadius: '20px !important',
                    textTransform: 'none !important',
                    minWidth: '20px !important',
                    fontWeight: 600,
                    backgroundImage: 'none !important',
                    '&:hover, &:focus': {
                      opacity: 0.8,
                      backgroundColor: `${ThemeBackgroundColor} !important`,
                      backgroundImage: 'none !important',
                    },
                  }}
                >
                  <SendIcon sx={{ color: ThemeFontColor }} />
                </Button>
              </div>)
              : (
                <div className={'minimizedContainer'} style={widgetPositionStyle}>
                  {callMessage && (
                    <div className={'callMessageContainer'}>
                      <span className={'callMessage'}>{callMessage}</span>
                      <span className={'closeCallMessage'}>X</span>
                    </div>
                  )}
                  <div className={'circle-icon'}>
                    {botProfilePicture ? (
                      <img src={botProfilePicture} alt="Bot" className={"bot-icon"} />
                    ) : (
                      <FontAwesomeIcon icon={faComments} />
                    )}
                    <div className={'badge'}>1</div>
                  </div>
                </div>
              )}
    </Box>
  );
};

export default ChatLayoutTab;
