import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Divider,
  Paper,
  Button,
  Switch,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControlLabel,
  Chip,
  InputAdornment
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import ConversationList from './ConversationList';
import ConversationDetail from './ConversationDetail';
import FilterOptions from './FilterOptions';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { toast } from 'react-toastify';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InstagramIcon from '../images/instagram.svg';
import MessengerIcon from '../images/messenger.svg';
import WhatsappIcon from '../images/whatsapp.webp';
import WebIcon from '../images/web.webp';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

dayjs.extend(utc);
dayjs.extend(timezone);

const DistributionListInput = ({ distributionList, setDistributionList }) => {
  const [distributionInput, setDistributionInput] = useState('');

  const handleAddEmail = () => {
    const email = distributionInput.trim();
    if (email && !distributionList.includes(email)) {
      setDistributionList([...distributionList, email]);
      setDistributionInput('');
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={distributionList}
      onChange={(event, newValue) => {
        setDistributionList(newValue);
      }}
      inputValue={distributionInput}
      onInputChange={(event, newInputValue) => {
        setDistributionInput(newInputValue);
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Distribution List"
          placeholder="Add email"
          margin="dense"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <InputAdornment position="end">
                  <IconButton onClick={handleAddEmail} edge="end">
                    <AddCircleOutlineIcon />
                  </IconButton>
                </InputAdornment>
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};



const ChatMessagesTab = ({ details }) => {

  const [chatbotId, setChatbotId] = useState('');
  const [messages, setMessages] = useState([]);
  const [reportsEnabled, setReportsEnabled] = useState([details.reports_enabled]);
  const [groupedMessages, setGroupedMessages] = useState({});
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [filters, setFilters] = useState({
    from: dayjs().subtract(7, 'day').format('YYYY-MM-DDTHH:mm'),
    to: dayjs().format('YYYY-MM-DDTHH:mm'),
    source: '',
    feedback: '',
  });
  const [sources, setSources] = useState([]);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // New states for Generate Insights dialog
  const [insightDialogOpen, setInsightDialogOpen] = useState(false);
  const [insightText, setInsightText] = useState(details.insights_prompt || "");
  const [insightFrom, setInsightFrom] = useState(dayjs().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm'));
  const [insightTo, setInsightTo] = useState(dayjs().format('YYYY-MM-DDTHH:mm'));
  const [insightTextLimit, setInsightTextLimit] = useState(details.insights_limit_char || 300);
  //const [insightSource, setInsightSource] = useState("");

  // New state for distribution list (email addresses)
  const [distributionList, setDistributionList] = useState([]);

  const iconStyle = { marginRight: '0.6rem', width: '1rem', height: '1rem' };

  useEffect(() => {
    if (details && details.id) {
      setChatbotId(details.id);
      setReportsEnabled(details.reports_enabled);
      setDistributionList(details.insights_dl || []);
      setInsightText(details.insights_prompt || "");
      setInsightTextLimit(details.insights_limit_char || 300);
    }
  }, [details]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!chatbotId) {
        console.error('AI assistant ID is undefined or null.');
        return;
      }
      const token = localStorage.getItem('access_token');
      const responsePromise = axios
        .get(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}/messages`, {
          headers: {
            Authorization: token,
          },
          params: {
            from: dayjs(filters.from).tz('UTC').format('YYYY-MM-DDTHH:mm'),
            to: dayjs(filters.to).tz('UTC').format('YYYY-MM-DDTHH:mm'),
            source: filters.source,
            feedback: filters.feedback,
          },
        })
        .then(response => {
          const msgs = response.data.messages.map(message => ({
            ...message,
            created: dayjs(message.created).tz(userTimezone).format('YYYY-MM-DDTHH:mm'),
          }));
          console.log('Fetched messages:', msgs);
          setMessages(msgs);
          const uniqueSources = [...new Set(msgs.map(message => message.source))];
          setSources(uniqueSources);
          return response;
        });
      toast.promise(
        responsePromise,
        {
          pending: 'Fetching conversations...',
          success: 'Conversations fetched successfully!',
          error: 'Failed to fetch conversations!',
        },
        { position: 'top-center' }
      );
      try {
        await responsePromise;
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    if (chatbotId) {
      fetchMessages();
    }
  }, [chatbotId, filters, userTimezone]);

  useEffect(() => {
    const groupMessagesByUser = () => {
      console.log('Applying filters:', filters);
      const grouped = messages.reduce((acc, message) => {
        const { end_user_id, source } = message;
        if (!acc[end_user_id]) {
          acc[end_user_id] = { messages: [], channelName: source };
        }
        acc[end_user_id].messages.push(message);
        return acc;
      }, {});
      console.log('Grouped messages:', grouped);
      setGroupedMessages(grouped);
      const userIds = Object.keys(grouped);
      if (userIds.length > 0) {
        setSelectedConversation(userIds[0]);
      } else {
        setSelectedConversation(null);
      }
    };

    groupMessagesByUser();
  }, [messages, filters, userTimezone]);

  const handleConversationClick = (userId) => {
    setSelectedConversation(userId);
  };

  const handleFilterChange = (newFilters) => {
    console.log('Filters updated:', newFilters);
    setFilters(newFilters);
  };

  const handleDownload = async () => {
    const token = localStorage.getItem('access_token');
    const downloadPromise = axios
      .get(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}/messages`, {
        headers: {
          Authorization: token,
        },
        params: {
          from: dayjs(filters.from).tz('UTC').format('YYYY-MM-DDTHH:mm'),
          to: dayjs(filters.to).tz('UTC').format('YYYY-MM-DDTHH:mm'),
          source: filters.source,
          feedback: filters.feedback,
          download: true,
        },
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers['content-type'] })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'chat_history.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
        return response;
      });
    toast.promise(
      downloadPromise,
      {
        pending: 'Downloading chat history...',
        success: 'Chat history downloaded successfully!',
        error: 'Failed to download chat history!',
      },
      { position: 'top-center' }
    );
    try {
      await downloadPromise;
    } catch (error) {
      console.error('Error downloading file', error);
    }
  };

  const handleInsightSave = async () => {
    const token = localStorage.getItem('access_token');
    const patchPayload = {
      reports_enabled: reportsEnabled,
      insights_dl: distributionList,
      insights_prompt: insightText,
      insights_limit_char: insightTextLimit,
    };
    try {
      const response = await toast.promise(
        axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, patchPayload, {
          headers: { Authorization: token },
        }),
        {
          pending: 'Updating daily insight settings...',
          success: 'Daily insight settings updated successfully!',
          error: 'Error updating daily insight settings',
        },
        { position: 'top-center' }
      );
      console.log('Insight settings response:', response.data);
      setInsightDialogOpen(false);
      setInsightFrom(dayjs().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm'));
      setInsightTo(dayjs().format('YYYY-MM-DDTHH:mm'));

    } catch (error) {
      console.error('Error patching chatbot:', error);
      return;
    }

  }


  // Handle the insight generation dialog submission
  const handleInsightSubmit = async () => {
    const token = localStorage.getItem('access_token');

    // First, patch the chatbot with the new daily insight settings

    // Now, send the generate insights request
    const payload = {
      from: insightFrom,
      to: insightTo,
      //source: insightSource,
      prompt: insightText,
      chatbot_id: chatbotId,
      limit: insightTextLimit,
    };

    try {
      const response = await toast.promise(
        axios.post(`${process.env.REACT_APP_API_URL}/api/generate_insights`, payload, {
          headers: { Authorization: token },
        }),
        {
          pending: 'Sending insights to your email...',
          success: 'Insights sent successfully!',
          error: 'Failed to generate insights!',
        },
        { position: 'top-center' }
      );
      console.log('Generated insights response:', response.data);

      // Optionally reset distributionList if desired:
      // setDistributionList([]);
    } catch (error) {
      console.error('Error generating insights:', error);
    }
  };

  return (
    <Box display="flex">
      <Paper square={false} elevation={4} style={{ height: '100%', width: '40%', marginRight: '2rem' }}>
        {/* Removed daily report switch from here */}
        <Box overflow="auto">
          <FilterOptions filters={filters} onFilterChange={handleFilterChange} sources={sources} />
          <Box p={2}>
            <Button variant="contained" color="primary" onClick={handleDownload}>
              Download Chat History
            </Button>
            <Tooltip title="Generate insights from the conversations.">
              <Button
                variant="outlined"
                onClick={() => setInsightDialogOpen(true)}
                style={{ marginLeft: '1rem' }}
              >
                Generate Insights
              </Button>
            </Tooltip>
          </Box>
          <ConversationList
            groupedMessages={groupedMessages}
            onConversationClick={handleConversationClick}
            selectedConversation={selectedConversation}
          />
        </Box>
      </Paper>
      <Paper square={false} elevation={4} style={{ height: '100%', width: '60%' }}>
        <Box>
          {selectedConversation && groupedMessages[selectedConversation] ? (
            <ConversationDetail messages={groupedMessages[selectedConversation].messages} />
          ) : (
            <Typography variant="h6" align="center" mt={4}>
              {Object.keys(groupedMessages).length === 0 ? 'No messages found' : 'Select a conversation to view details'}
            </Typography>
          )}
        </Box>
      </Paper>
      {/* Generate Insights Dialog */}
      <Dialog open={insightDialogOpen} onClose={() => setInsightDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Generate Insights</DialogTitle>
        <DialogContent>

          <TextField
            margin="dense"
            id="insight-from"
            label="From"
            type="datetime-local"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={insightFrom}
            onChange={(e) => setInsightFrom(e.target.value)}
          />
          <TextField
            margin="dense"
            id="insight-to"
            label="To"
            type="datetime-local"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={insightTo}
            onChange={(e) => setInsightTo(e.target.value)}
          />
          <TextField
            margin="dense"
            id="insight-textLimit"
            label="Response Limit (words)"
            type="number"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={insightTextLimit}
            onChange={(e) => setInsightTextLimit(e.target.value)}
          />
          {/*<FormControl fullWidth margin="normal" sx={{ backgroundColor: 'white' }}>
            <InputLabel>Source</InputLabel>
            <Select
              value={insightSource}
              onChange={(e) => setInsightSource(e.target.value)}
              label="Source"
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              <MenuItem value="web">
                <img src={WebIcon} alt="Web" style={iconStyle} />
                Web
              </MenuItem>
              <MenuItem value="facebook">
                <img src={MessengerIcon} alt="Facebook" style={iconStyle} />
                Facebook
              </MenuItem>
              <MenuItem value="instagram">
                <img src={InstagramIcon} alt="Instagram" style={iconStyle} />
                Instagram
              </MenuItem>
              <MenuItem value="whatsapp">
                <img src={WhatsappIcon} alt="Whatsapp" style={iconStyle} />
                Whatsapp
              </MenuItem>
            </Select>
          </FormControl> */}
          <TextField
            margin="dense"
            id="insight-text"
            label="Insight Prompt"
            type="text"
            fullWidth
            multiline
            minRows={3}
            value={insightText}
            onChange={(e) => setInsightText(e.target.value)}
          />
          <Divider sx={{ mt: 2 }} />

          {/* New fields for daily insight settings */}
          <FormControlLabel
            control={
              <Switch
                checked={reportsEnabled}
                onChange={(e) => setReportsEnabled(e.target.checked)}
              />
            }
            label="Enable Daily Insight Email"
          />
          <Typography variant="body2" color="textSecondary">
          Daily insights will be delivered to the email addresses listed below at 09:00 AWST. You also have the option to generate insights manually, which will be sent exclusively to your email.          </Typography>
          <DistributionListInput
            distributionList={distributionList}
            setDistributionList={setDistributionList}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setInsightDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleInsightSubmit} variant="outlined" color="primary">
            Send me an insight now
          </Button>
          <Button onClick={handleInsightSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatMessagesTab;
