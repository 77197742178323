import React, { useState, useEffect } from 'react';
import {
    DndContext,
    closestCenter,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
    Box,
    TextField,
    Typography,
    Button,
    Tab,
    Tabs,
    Paper,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Tooltip,
    IconButton,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    CircularProgress,
    Chip,
    DialogContentText,
    Unstable_Grid2 as Grid,
    createFilterOptions,
    Stack,
    FormHelperText,
    useMediaQuery,
    Switch,
    FormControlLabel,
    List,
    ListItem,
    Checkbox,
    Autocomplete,
    ListItemText,
    ListItemSecondaryAction,
    Slider
} from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'; // Importing Drag Handle Icon
import InputAdornment from '@mui/material/InputAdornment';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { TableVirtuoso } from 'react-virtuoso';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Spinner from './Spinner';
import TimezoneSelect from 'react-timezone-select';
import { useTheme } from '@mui/material/styles';
import { toast } from 'react-toastify';
import CustomSelect from 'react-select';
import QuoteTab from './QuoteTab';
import FileUpload from './FileUpload';
import { useParams } from 'react-router-dom';
import ServiceQuote from './ServiceQuote';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import Cropper from 'react-easy-crop'; // New: for cropping booking logo



// SortableListItem Component for drag-and-drop
const SortableListItem = ({ field, onDelete, onToggleMandatory }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id: field.name });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: 'grab',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        padding: '8px 16px',
        borderRadius: '4px',
        marginBottom: '4px',
    };

    return (
        <ListItem ref={setNodeRef} style={style} {...attributes} {...listeners} disableGutters>
            <IconButton
                {...listeners}
                style={{ marginRight: '8px', cursor: 'grab' }}
                size="small"
            >
                <DragIndicatorIcon />
            </IconButton>
            <ListItemText
                primary={
                    <Typography
                        variant="body1"
                        sx={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {field.name}
                    </Typography>
                }
                sx={{
                    flex: '0 0 200px', // Prevent flex-grow and set fixed width
                    marginRight: '16px', // Space between field name and checkbox
                }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={field.mandatory}
                        onChange={() => onToggleMandatory(field.name)}
                        color="primary"
                    />
                }
                label="Mandatory"
            />
            <IconButton edge="end" color="error" onClick={() => onDelete(field.name)}>
                <DeleteIcon />
            </IconButton>
        </ListItem>
    );
};

const PhoneTextField = React.forwardRef(({ value, onChange, ...rest }, ref) => {
    return (
        <TextField
            {...rest}
            value={value || ''}
            onChange={(event) => onChange(event.target.value || '')}
            fullWidth
            margin="normal"
            inputRef={ref}
        />
    );
});

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer
            component={Paper}
            sx={{ elevation: 1, variant: "outlined", square: false, height: '100%', overflowX: 'auto' }}
            {...props}
            ref={ref}
        />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'auto', minWidth: '600px' }} />
    ),
    TableHead: React.forwardRef((props, ref) => <TableHead {...props} ref={ref} />),
    TableRow,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

// Helper functions for ISO 8601 conversions
const convertToIsoDuration = (hours, minutes) => {
    const h = parseInt(hours, 10) || 0;
    const m = parseInt(minutes, 10) || 0;
    if (h === 0 && m === 0) {
        return null;
    }
    return `PT${h > 0 ? `${h}H` : ''}${m > 0 ? `${m}M` : ''}`;
};

const convertFromIsoDuration = (isoDuration) => {
    if (!isoDuration) {
        return { hours: '', minutes: '' };
    }
    // This regex works for durations in the format PT#H#M
    const regex = /PT(?:(\d+)H)?(?:(\d+)M)?/;
    const matches = isoDuration.match(regex);
    return {
        hours: (matches && matches[1]) ? matches[1] : '00',
        minutes: (matches && matches[2]) ? matches[2] : '00'
    };
};

// For booking_window_end (which may include days), we use a different converter.
const convertToIsoDurationDays = (days, hours) => {
    const d = parseInt(days, 10) || 0;
    const h = parseInt(hours, 10) || 0;
    if (d === 0 && h === 0) {
        return null;
    }
    let result = 'P';
    if (d > 0) {
        result += `${d}D`;
    }
    if (h > 0) {
        result += `T${h}H`;
    }
    return result;
};

const convertFromIsoDurationDays = (isoDuration) => {
    if (!isoDuration) {
        return { days: '', hours: '' };
    }
    const regex = /P(?:(\d+)D)?(?:T(?:(\d+)H)?)?/;
    const matches = isoDuration.match(regex);
    return {
        days: (matches && matches[1]) ? matches[1] : '0',
        hours: (matches && matches[2]) ? matches[2] : '0'
    };
};

const BookingTab = () => {
    const { id } = useParams();
    const [isSaving, setIsSaving] = useState(false);
    const [activeSubtab, setActiveSubtab] = useState('services');
    const [servicesError, setServicesError] = useState(false);
    const [timeSlotErrors, setTimeSlotErrors] = useState({});
    const [dialogMessage, setDialogMessage] = useState('');
    const [onConfirm, setOnConfirm] = useState(() => () => { });
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const details = localStorage.getItem('aissie-chatbot-details-' + id)
        ? JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id))
        : [];
    const [chatbotId, setChatbotId] = useState(details.id);
    const [durationError, setDurationError] = useState(false);
    const [minDurationError, setMinDurationError] = useState(false);
    const [minCancellationError, setMinCancellationError] = useState(false);
    const [serviceNameError, setServiceNameError] = useState(false);
    const [serviceTypeError, setServiceTypeError] = useState(false);
    const [services, setServices] = useState([]);
    const [resources, setResources] = useState([]);
    const [locations, setLocations] = useState([]);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [quoteInstructions, setQuoteInstructions] = useState(details.quote_instructions || '');
    const [bookingEnabled, setBookingEnabled] = useState(details.booking_enabled || false);
    const [fields, setFields] = useState(details.booking_configuration.fields);
    const [calendarURL, setCalendarURL] = useState(details.calendar_URL || '');
    const filter = createFilterOptions();

    const [isModified, setIsModified] = useState(false);
    const [hasFlexibleService, setHasFlexibleService] = useState(false);
    const [mobile, setMobile] = useState(details.mobile || '');
    const [quoteWhatsapp, setQuoteWhatsapp] = useState(details.quote_whatsapp || false)
    const [isFetching, setIsFetching] = useState(false);
    const [serviceDialogOpen, setServiceDialogOpen] = useState(false);
    const [locationDialogOpen, setLocationDialogOpen] = useState(false);
    const [resourceDialogOpen, setResourceDialogOpen] = useState(false);
    // NEW: State for booking logo upload and cropping
    const [bookingLogo, setBookingLogo] = useState(
        details.account_id
            ? `https://aissieragfiles.blob.core.windows.net/booking-logos/${details.account_id}/cropped_booking_logo.png`
            : null
    );
    const [bookingLogoFileId, setBookingLogoFileId] = useState(details.booking_logo || null);
    const [bookingLogoRaw, setBookingLogoRaw] = useState(null);
    const [bookingLogoSelectedImage, setBookingLogoSelectedImage] = useState(null);
    const [bookingLogoCrop, setBookingLogoCrop] = useState({ x: 0, y: 0 });
    const [bookingLogoZoom, setBookingLogoZoom] = useState(1);
    const [bookingLogoCroppedAreaPixels, setBookingLogoCroppedAreaPixels] = useState(null);
    const [openBookingLogoCropDialog, setOpenBookingLogoCropDialog] = useState(false);
    const [bookingConfirmationEmailContent, setBookingConfirmationEmailContent] = useState(
        details.booking_confirmation_email_content || ''
    );
    // Initialize newService with additional fields for flexible services
    const [newService, setNewService] = useState({
        name: '',
        price: '',
        type: '',
        tools: [],
        duration_step_hours: '',
        duration_step_minutes: '',
        default_duration_hours: '',
        default_duration_minutes: '',
        bookable_interval_hours: '',
        bookable_interval_minutes: '',
        booking_window_start_hours: '',
        booking_window_start_minutes: '',
        booking_window_end_days: '',
        booking_window_end_hours: '',
        buffer_time_before_hours: '',
        buffer_time_before_minutes: '',
        buffer_time_after_hours: '',
        buffer_time_after_minutes: '',
        booking_enabled: true,
        quote_enabled: false,
    });
    const [newLocation, setNewLocation] = useState({
        name: '',
        timezone: { value: 'Australia/Perth', label: 'Australia/Perth' },
    });
    const [chatbotTools, setChatbotTools] = useState(() => {
        const flexibleServices = services?.filter(service => service.type === 'flexible');
        if (flexibleServices.length > 0) {
            const serviceNames = flexibleServices?.map(service => service.name);
            const itemAnyOfArray = flexibleServices.map(service => {
                const originalProperties = JSON.parse(JSON.stringify(service.tools.properties || {}));
                const properties = {
                    service_type: {
                        type: 'string',
                        enum: [service.name],
                        description: 'Discriminator to distinguish between services',
                    },
                    ...originalProperties,
                };
                const requiredFields = Object.keys(properties);
                for (const key in properties) {
                    if (properties[key].enum && !Array.isArray(properties[key].enum)) {
                        properties[key].enum = [properties[key].enum];
                    }
                }
                return {
                    type: 'object',
                    description: `The ${service.name} service mandatory parameters. Ensure these parameters are obtained based on the service.`,
                    properties: properties,
                    required: requiredFields,
                    additionalProperties: false
                };
            });
            const jsonSchema = {
                type: 'function',
                function: {
                    name: 'get_price_and_duration',
                    strict: true,
                    description: 'Retrieves the price and duration for a service.',
                    parameters: {
                        type: 'object',
                        properties: {
                            service_name: {
                                type: 'string',
                                enum: serviceNames,
                                description: 'The name of the service'
                            },
                            item: {
                                anyOf: itemAnyOfArray
                            },

                        },
                        required: ['item', 'service_name'],
                        additionalProperties: false
                    }
                }
            };
            console.log(JSON.stringify(jsonSchema, null, 2));
            return jsonSchema;
        } else return [];
    });
    const [editResource, setEditResource] = useState(null);
    const [editService, setEditService] = useState(null);
    const [editLocation, setEditLocation] = useState({
        name: '',
        timezone: { value: 'Australia/Perth', label: 'Australia/Perth' },
    });
    const [files, setFiles] = useState([]);
    const [selectedTab, setSelectedTab] = useState('services');
    // Initialize sensors for drag-and-drop
    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 5,
            },
        })
    );

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
            setFields((items) => {
                const oldIndex = items.findIndex((field) => field.name === active.id);
                const newIndex = items.findIndex((field) => field.name === over.id);
                return arrayMove(items, oldIndex, newIndex);
            });
        }
    };
    // --- Helper functions for cropping (can be reused) ---
    const createImage = (url) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener('load', () => resolve(image));
            image.addEventListener('error', (error) => reject(error));
            image.setAttribute('crossOrigin', 'anonymous');
            image.src = url;
        });
    // Save chatbot state to localStorage
    const saveStateToLocalStorage = () => {
        const existingState = JSON.parse(localStorage.getItem('aissie-chatbot-details-' + id)) || {};
        const newState = {
            id: chatbotId,
            quote_instructions: quoteInstructions,
            tools: chatbotTools
        };
        const updatedState = { ...existingState, ...newState };
        localStorage.setItem('aissie-chatbot-details-' + id, JSON.stringify(updatedState));
    };

    const getBookingLogoCroppedImg = async (imageSrc, pixelCrop) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            pixelCrop.width,
            pixelCrop.height
        );
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return reject(new Error('Canvas is empty'));
                }
                blob.name = 'cropped_booking_logo.png';
                resolve(blob);
            }, 'image/png');
        });
    };


    useEffect(() => {
        if (isModified) {
            saveStateToLocalStorage();
        }
    }, [isSaving]);
    // NEW: Handlers for booking logo upload and cropping
    const handleBookingLogoFileChange = (event) => {
        const file = event.target.files[0];
        if (
            file &&
            (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml') &&
            file.size <= 1048576
        ) {
            const imageUrl = URL.createObjectURL(file);
            setBookingLogoRaw(file);
            setBookingLogoSelectedImage(imageUrl);
            setOpenBookingLogoCropDialog(true);
        } else {
            alert('File must be JPG, PNG, or SVG and less than 1MB');
        }
    };

    useEffect(() => {
        const hasFlexible = services.some(service => service.type === 'flexible');
        setHasFlexibleService(hasFlexible);
    }, [services]);

    useEffect(() => {
        setChatbotTools(() => {
            const flexibleServices = services?.filter(service => service.type === 'flexible');
            if (flexibleServices.length > 0) {
                const serviceNames = flexibleServices?.map(service => service.name);
                const itemAnyOfArray = flexibleServices.map(service => {
                    const originalProperties = JSON.parse(JSON.stringify(service.tools.properties || {}));
                    const properties = {
                        service_type: {
                            type: 'string',
                            const: service.name,
                            description: 'Discriminator to distinguish between services',
                        },
                        ...originalProperties,
                    };
                    const requiredFields = Object.keys(properties);
                    for (const key in properties) {
                        if (properties[key].enum && !Array.isArray(properties[key].enum)) {
                            properties[key].enum = [properties[key].enum];
                        }
                    }
                    return {
                        type: 'object',
                        description: `The ${service.name} service mandatory parameters. Ensure these parameters are obtained based on the service.`,
                        properties: properties,
                        required: requiredFields,
                        additionalProperties: false
                    };
                });
                const jsonSchema = {
                    type: 'function',
                    function: {
                        name: 'get_price_and_duration',
                        strict: true,
                        description: 'Retrieves the price and duration for a service.',
                        parameters: {
                            type: 'object',
                            properties: {
                                service_name: {
                                    type: 'string',
                                    enum: serviceNames,
                                    description: 'The name of the service'
                                },
                                item: {
                                    anyOf: itemAnyOfArray
                                },
                            },
                            required: ['item', 'service_name'],
                            additionalProperties: false
                        }
                    }
                };
                console.log(JSON.stringify(jsonSchema, null, 2));
                return jsonSchema;
            } else return null;
        });
    }, [services]);

    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    const initializeWorkingHours = () => {
        const workingHours = {
            recurring_schedules: [{
                recurring_schedule_id: null,
                schedule_blocks: []
            }]
        };
        daysOfWeek.forEach(day => {
            if (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day)) {
                workingHours.recurring_schedules[0].schedule_blocks.push({
                    schedule_block: {
                        weekday: day,
                        start_time: '08:00',
                        end_time: '17:00'
                    },
                    schedule_block_id: null
                });
            }
        });
        return workingHours;
    };
    const handleSaveClick = async () => {
        const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
        const saveChatbot = async () => {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
                calendar_URL: calendarURL,
            }, {
                headers: {
                    Authorization: token,
                },
            });
        };

        toast.promise(
            saveChatbot(),
            {
                pending: 'Saving AI assistant...',
                success: 'AI assistant saved successfully!',
                error: 'Failed to save AI assistant.'
            }, {
            position: "top-center"
        }
        ).catch(error => {
            console.error('Error saving AI assistant:', error);
        })
    };


    const isOverlappingOrInclusive = (day, newStart, newEnd, excludeIndex = null) => {
        const resource = editResource ? editResource : newResource;
        const recurringSchedule = resource?.working_hours?.recurring_schedules?.[0];
        if (!recurringSchedule) {
            console.error("No recurring schedule found");
            return false;
        }
        const existingSlots = recurringSchedule.schedule_blocks
            .map((slot, index) => ({ slot, index }))
            .filter(({ slot, index }) => slot.schedule_block.weekday === day && index !== excludeIndex);
        const newStartTime = parseTime(newStart);
        const newEndTime = parseTime(newEnd);
        if (newStartTime === null || newEndTime === null) {
            console.error("Invalid new start or end time");
            return false;
        }
        return existingSlots.some(({ slot }) => {
            const existingStartTime = parseTime(slot.schedule_block.start_time);
            const existingEndTime = parseTime(slot.schedule_block.end_time);
            if (existingStartTime === null || existingEndTime === null) {
                return false;
            }
            return newStartTime < existingEndTime && newEndTime > existingStartTime;
        });
    };

    const parseTime = (timeStr) => {
        if (!timeStr) return null;
        const [hoursStr, minutesStr] = timeStr.split(':');
        const hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        if (isNaN(hours) || isNaN(minutes)) {
            return null;
        }
        return hours * 60 + minutes;
    };

    const [newResource, setNewResource] = useState({
        name: '',
        working_hours: initializeWorkingHours(),
        services: []
    });

    useEffect(() => {
        fetchFiles();
    }, []);

    const handleDeleteFile = async (fileId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/sources/${fileId}`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                }).then(response => {
                    fetchFiles();
                }),
                {
                    pending: 'Deleting quote file...',
                    success: 'Quote file deleted successfully!',
                    error: 'Error deleting quote file',
                },
                {
                    position: "top-center"
                }
            );
        } catch (error) {
            console.error('Error deleting quote file:', error);
        }
    };
    useEffect(() => {
        if (editResource) {
            const processWorkingHours = (workingHours) => {
                const formattedWorkingHours = {
                    recurring_schedules: workingHours.recurring_schedules.map(schedule => ({
                        recurring_schedule_id: schedule.recurring_schedule_id,
                        schedule_blocks: schedule.schedule_blocks.map((block) => ({
                            schedule_block: {
                                weekday: block.schedule_block.weekday,
                                start_time: block.schedule_block.start_time,
                                end_time: block.schedule_block.end_time
                            },
                            schedule_block_id: block.schedule_block_id
                        }))
                    }))
                };
                return formattedWorkingHours;
            };
            setNewResource({
                name: editResource.name || '',
                working_hours: processWorkingHours(editResource.working_hours),
                services: editResource.services || [],
                id: editResource.id || null
            });
        } else {
            setNewResource({
                name: '',
                working_hours: initializeWorkingHours(),
                services: []
            });
        }
    }, [editResource]);

    const handleWorkingHoursChange = (day, dayIndex, field, value) => {
        const resource = editResource ? editResource : newResource;
        const updatedHours = { ...resource.working_hours };
        const recurringSchedule = updatedHours.recurring_schedules[0];
        if (!recurringSchedule) {
            console.error(`No recurring schedule found at index 0`);
            return;
        }
        const scheduleBlocksForDayWithIndex = recurringSchedule.schedule_blocks.reduce((acc, block, index) => {
            if (block.schedule_block.weekday === day) {
                acc.push({ block, index });
            }
            return acc;
        }, []);
        const scheduleBlockWithIndex = scheduleBlocksForDayWithIndex[dayIndex];
        if (!scheduleBlockWithIndex) {
            console.error(`No schedule block found for day ${day} at index ${dayIndex}`);
            return;
        }
        const { block: scheduleBlock, index: blockIndex } = scheduleBlockWithIndex;
        const newBlock = {
            ...scheduleBlock,
            schedule_block: { ...scheduleBlock.schedule_block, [field]: value }
        };
        let errors = { ...timeSlotErrors };
        const startTime = newBlock.schedule_block.start_time;
        const endTime = newBlock.schedule_block.end_time;
        if (startTime && endTime) {
            const startMinutes = parseTime(startTime);
            const endMinutes = parseTime(endTime);
            if (startMinutes === null || endMinutes === null) {
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Invalid time format';
            } else if (startMinutes >= endMinutes) {
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Start time must be before end time';
            } else if (isOverlappingOrInclusive(day, startTime, endTime, blockIndex)) {
                if (!errors[day]) errors[day] = {};
                errors[day][dayIndex] = 'Time slot overlaps with an existing slot';
            } else {
                if (errors[day]) delete errors[day][dayIndex];
            }
        } else {
            if (errors[day]) delete errors[day][dayIndex];
        }
        if (blockIndex !== undefined) {
            updatedHours.recurring_schedules[0].schedule_blocks[blockIndex] = newBlock;
        }
        if (editResource) {
            setEditResource({ ...editResource, working_hours: updatedHours });
        } else {
            setNewResource({ ...newResource, working_hours: updatedHours });
        }
        setTimeSlotErrors(errors);
    };
    // Handle toggling the mandatory status
    const handleToggleMandatory = (fieldName) => {
        const updatedFields = fields.map((field) =>
            field.name === fieldName ? { ...field, mandatory: !field.mandatory } : field
        );
        setFields(updatedFields);
    };

    const handleFieldsChange = (event, newValue, reason) => {
        if (reason === 'remove-option') {
            setFields(newValue.map(field => ({ name: field.name || field, mandatory: field.mandatory || false })));
        } else if (typeof newValue === 'string') {
            // Prevent adding fields that already exist
            if (!fields.find(f => f.name === newValue)) {
                setFields([...fields, { name: newValue, mandatory: false }]);
            }
        } else if (newValue && newValue.inputValue) {
            // Prevent adding fields that already exist
            if (!fields.find(f => f.name === newValue.inputValue)) {
                setFields([...fields, { name: newValue.inputValue, mandatory: false }]);
            }
        } else {
            // Handle adding fields from free solo input
            const addedFields = newValue.filter(option => typeof option === 'string' && !fields.find(f => f.name === option));
            const formattedFields = addedFields.map(fieldName => ({ name: fieldName, mandatory: false }));
            setFields([...fields, ...formattedFields]);
        }
    };

    const handleBookingLogoCropConfirm = async () => {
        try {
            const croppedImageBlob = await getBookingLogoCroppedImg(bookingLogoSelectedImage, bookingLogoCroppedAreaPixels);
            const croppedFile = new File([croppedImageBlob], "cropped_booking_logo.png", { type: "image/png" });
            const formData = new FormData();
            formData.append('file', croppedFile);
            formData.append('chatbot_id', chatbotId);
            formData.append('type', 'booking_logo');
            const token = localStorage.getItem('access_token');
            const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: token,
                },
            });
            const newFileName = uploadResponse.data[0].filename;
            setBookingLogo(`https://aissieragfiles.blob.core.windows.net/booking-logos/${newFileName}?t=${new Date().getTime()}`);
            setBookingLogoFileId(newFileName);
            toast.success('Booking logo uploaded successfully!', { position: "top-center" });
        } catch (error) {
            toast.error('Failed to upload booking logo.', { position: "top-center" });
            console.error('Error cropping/uploading booking logo:', error);
        } finally {
            setOpenBookingLogoCropDialog(false);
        }
    };

    const handleBookingLogoCropCancel = () => {
        setOpenBookingLogoCropDialog(false);
        setBookingLogoSelectedImage(null);
    };

    const handleBookingLogoReCrop = () => {
        if (bookingLogoRaw) {
            setBookingLogoSelectedImage(URL.createObjectURL(bookingLogoRaw));
            setOpenBookingLogoCropDialog(true);
        }
    };

    const handleSaveChatbot = () => {
        setIsSaving(true);
        const token = localStorage.getItem('access_token');
        const saveQuoteInstructions = async () => {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
                quote_instructions: quoteInstructions,
                tools: chatbotTools,
                quote_mobile: mobile,
                quote_whatsapp: quoteWhatsapp,
            }, {
                headers: { Authorization: token },
            });
        };
        toast.promise(
            saveQuoteInstructions(),
            {
                pending: 'Saving AI assistant...',
                success: 'AI assistant saved successfully!',
                error: 'Failed to save AI assistant.'
            },
            { position: "top-center" }
        ).catch(error => {
            console.error('Error saving AI assistant:', error);
        }).finally(() => {
            setIsSaving(false);
            setIsModified(true);
        });
    };

    const handleAddTimeSlot = (day) => {
        const resource = editResource ? editResource : newResource;
        const updatedSchedules = [...(resource.working_hours?.recurring_schedules || [])];
        if (updatedSchedules.length === 0) {
            updatedSchedules.push({ schedule_blocks: [] });
        }
        const newBlock = {
            schedule_block: { weekday: day, start_time: '', end_time: '' },
            schedule_block_id: null,
        };
        updatedSchedules[0].schedule_blocks.push(newBlock);
        const updatedWorkingHours = { ...resource.working_hours, recurring_schedules: updatedSchedules };
        if (editResource) {
            setEditResource({ ...editResource, working_hours: updatedWorkingHours });
        } else {
            setNewResource({ ...newResource, working_hours: updatedWorkingHours });
        }
    };

    const convertWorkingHoursToScheduleBlocks = (workingHours) => {
        if (!workingHours.recurring_schedules || !workingHours.recurring_schedules.length) {
            return { recurring_schedules: [] };
        }
        const recurringSchedules = workingHours.recurring_schedules.map(schedule => ({
            recurring_schedule_id: schedule.recurring_schedule_id,
            schedule_blocks: schedule.schedule_blocks.map((slot) => ({
                schedule_block: {
                    weekday: slot.schedule_block.weekday,
                    start_time: slot.schedule_block.start_time,
                    end_time: slot.schedule_block.end_time
                },
                schedule_block_id: slot.schedule_block_id || null
            }))
        }));
        return { recurring_schedules: recurringSchedules };
    };

    const handleSaveLeadsConfig = async () => {
        const localStorageKey = `aissie-chatbot-details-${id}`;
        let chatbotDetails = JSON.parse(localStorage.getItem(localStorageKey));

        if (chatbotDetails) {
            chatbotDetails.booking_configuration = {
                fields,
            };

            localStorage.setItem(localStorageKey, JSON.stringify(chatbotDetails));
        }

        const token = localStorage.getItem('access_token');
        const saveChatbot = async () => {
            await axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${details.id}`, {
                booking_configuration: {
                    fields,
                    booking_confirmation_email_content: bookingConfirmationEmailContent,
                    booking_logo: bookingLogoFileId,
                }
            }, {
                headers: {
                    Authorization: token,
                },
            });
        }

        toast.promise(
            saveChatbot(),
            {
                pending: 'Saving AI assistant...',
                success: 'AI assistant saved successfully!',
                error: 'Failed to save AI assistant.'
            }, {
            position: "top-center"
        }).catch(error => {
            console.error('Error saving AI assistant:', error);
        })
    }

    const handleRemoveTimeSlot = (day, index) => {
        const resource = editResource ? editResource : newResource;
        const updatedSchedules = [...(resource.working_hours?.recurring_schedules || [])];
        if (updatedSchedules.length > 0) {
            const scheduleBlocks = [...updatedSchedules[0].schedule_blocks];
            const dayBlocks = scheduleBlocks.filter(block => block.schedule_block.weekday === day);
            if (dayBlocks.length > 0) {
                const blockToRemove = dayBlocks[index];
                const blockIndex = scheduleBlocks.indexOf(blockToRemove);
                if (blockIndex > -1) {
                    scheduleBlocks.splice(blockIndex, 1);
                }
            }
            updatedSchedules[0].schedule_blocks = scheduleBlocks;
        }
        const updatedWorkingHours = { ...resource.working_hours, recurring_schedules: updatedSchedules };
        if (editResource) {
            setEditResource({ ...editResource, working_hours: updatedWorkingHours });
        } else {
            setNewResource({ ...newResource, working_hours: updatedWorkingHours });
        }
    };

    // Fetch services and convert durations (including new flexible fields)
    const fetchServices = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/services`, {
                headers: { Authorization: `${token}` },
            });
            const fetchedServices = Array.isArray(response.data.message) ? response.data.message : [];
            const servicesWithConvertedDuration = fetchedServices.map(service => ({
                ...service,
                duration_hours: convertFromIsoDuration(service.duration).hours,
                duration_minutes: convertFromIsoDuration(service.duration).minutes,
                min_duration_hours: convertFromIsoDuration(service.min_duration).hours,
                min_duration_minutes: convertFromIsoDuration(service.min_duration).minutes,
                max_duration_hours: convertFromIsoDuration(service.max_duration).hours,
                max_duration_minutes: convertFromIsoDuration(service.max_duration).minutes,
                cancellation_threshold_hours: convertFromIsoDuration(service.cancellation_threshold).hours,
                // New flexible fields
                duration_step_hours: convertFromIsoDuration(service.duration_step).hours,
                duration_step_minutes: convertFromIsoDuration(service.duration_step).minutes,
                default_duration_hours: convertFromIsoDuration(service.default_duration).hours,
                default_duration_minutes: convertFromIsoDuration(service.default_duration).minutes,
                bookable_interval_hours: convertFromIsoDuration(service.bookable_interval).hours,
                bookable_interval_minutes: convertFromIsoDuration(service.bookable_interval).minutes,
                booking_window_start_hours: convertFromIsoDuration(service.booking_window_start).hours,
                booking_window_start_minutes: convertFromIsoDuration(service.booking_window_start).minutes,
                booking_window_end_days: convertFromIsoDurationDays(service.booking_window_end).days,
                booking_window_end_hours: convertFromIsoDurationDays(service.booking_window_end).hours,
                buffer_time_before_hours: convertFromIsoDuration(service.buffer_time_before).hours,
                buffer_time_before_minutes: convertFromIsoDuration(service.buffer_time_before).minutes,
                buffer_time_after_hours: convertFromIsoDuration(service.buffer_time_after).hours,
                buffer_time_after_minutes: convertFromIsoDuration(service.buffer_time_after).minutes,
                // New fields with defaults if not provided by API
                booking_enabled: service.booking_enabled !== undefined ? service.booking_enabled : true,
                quote_enabled: service.quote_enabled !== undefined ? service.quote_enabled : false,
            }));
            setServices(servicesWithConvertedDuration);
        } catch (error) {
            console.error('Error fetching services:', error);
        }
        setIsFetching(false);
    };

    const handleSuccessfulUpload = (uploadedFilesData) => {
        console.log('Uploaded Files Data:', uploadedFilesData);
        fetchFiles();
    };

    const fetchFiles = async () => {
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/sources?chatbot=${chatbotId}&type='quote`, {
                headers: { Authorization: token },
            });
            setFiles(response.data || []);
        } catch (error) {
            console.error('Error fetching quote files:', error);
        }
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: '#fff',
            borderColor: state.isFocused ? '#092F36' : '#ccc',
            '&:hover': { borderColor: '#092F36' },
            boxShadow: state.isFocused ? '0 0 0 1px #092F36' : 'none',
            height: '56px',
            minHeight: '56px',
        }),
        multiValue: (provided) => ({ ...provided, backgroundColor: '#f5f5f5' }),
        multiValueLabel: (provided) => ({ ...provided, color: '#06525B' }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#06525B',
            '&:hover': { backgroundColor: '#e5e5e5', color: '#092F36' },
        }),
        placeholder: (provided) => ({ ...provided, color: '#9e9e9e' }),
        menu: (provided) => ({ ...provided, backgroundColor: '#fff', zIndex: 9999 }),
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#e5e5e5' : '#fff',
            color: state.isSelected ? '#092F36' : '#000',
            '&:hover': { backgroundColor: '#e5e5e5' },
        }),
    };

    const openConfirmDialog = (message, onConfirmAction) => {
        setDialogMessage(message);
        setOnConfirm(() => onConfirmAction);
        setConfirmDialogOpen(true);
    };

    // Fetch Locations Data
    const fetchLocations = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/locations`, {
                headers: { Authorization: `${token}` },
            });
            setLocations(response.data.message);
        } catch (error) {
            console.error('Error fetching locations:', error);
        }
        setIsFetching(false);
    };

    // Fetch Resources Data
    const fetchResources = async () => {
        setIsFetching(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resources`, {
                headers: { Authorization: `${token}` },
            });
            setResources(response.data.message);
        } catch (error) {
            console.error('Error fetching resources:', error);
        }
        setIsFetching(false);
    };

    useEffect(() => {
        if (activeSubtab === 'services') {
            fetchServices();
        } else if (activeSubtab === 'resources') {
            fetchResources();
        } else if (activeSubtab === 'locations') {
            fetchLocations();
        }
    }, [activeSubtab]);

    const handleAddLocation = async () => {
        const endpoint = `${process.env.REACT_APP_API_URL}/api/locations`;
        try {
            const locationData = {
                ...newLocation,
                timezone: newLocation.timezone.value,
            };
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.post(endpoint, locationData, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    setLocationDialogOpen(false);
                    fetchLocations();
                }),
                {
                    pending: 'Creating timezone...',
                    success: 'Timezone created successfully!',
                    error: 'Error creating timezone',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    // Handle adding a new service (for both fixed and flexible types)
    const handleAddService = async () => {
        const duplicateService = services.find(
            (service) => service.name.toLowerCase() === newService.name.toLowerCase()
        );
        if (duplicateService) {
            toast.error('A service with the same name already exists.', { position: "top-center" });
            return;
        }
        const serviceType = newService.type;
        if (!validateMinCancellation(newService) && serviceType === 'flexible') return;
        if (!validateServiceName(newService.name)) return;
        if (!validateServiceType(serviceType)) return;
        if (!validateDuration(newService)) return;
        const duration = convertToIsoDuration(newService.duration_hours, newService.duration_minutes);
        const min_duration = convertToIsoDuration(newService.min_duration_hours, newService.min_duration_minutes);
        const max_duration = convertToIsoDuration(newService.max_duration_hours, newService.max_duration_minutes);
        const cancellation_threshold = convertToIsoDuration(newService.cancellation_threshold_hours);
        let price = parseFloat(newService.price);
        if (!isNaN(price)) {
            price = price.toFixed(3);
        }
        const formattedItem = {
            ...newService,
            duration,
            min_duration,
            max_duration,
            cancellation_threshold,
            bookable_interval: convertToIsoDuration(newService.bookable_interval_hours, newService.bookable_interval_minutes),
            booking_window_start: convertToIsoDuration(newService.booking_window_start_hours, newService.booking_window_start_minutes),
            booking_window_end: convertToIsoDurationDays(newService.booking_window_end_days, newService.booking_window_end_hours),
            buffer_time_before: convertToIsoDuration(newService.buffer_time_before_hours, newService.buffer_time_before_minutes),
            buffer_time_after: convertToIsoDuration(newService.buffer_time_after_hours, newService.buffer_time_after_minutes),
            duration_step: convertToIsoDuration(newService.duration_step_hours, newService.duration_step_minutes),
            default_duration: convertToIsoDuration(newService.default_duration_hours, newService.default_duration_minutes),
            ...(price && { price }),
            ...(newService.type === 'fixed' && { tools: [] }),
            // Include new fields in the payload
            booking_enabled: newService.booking_enabled,
            quote_enabled: newService.quote_enabled,
        };
        const endpoint = `${process.env.REACT_APP_API_URL}/api/services`;
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                (async () => {
                    await axios.post(endpoint, formattedItem, {
                        headers: { Authorization: `${token}` },
                    });
                    setServiceDialogOpen(false);
                    await fetchServices();
                })(),
                {
                    pending: 'Creating service...',
                    success: 'Service created successfully!',
                    error: 'Error creating service',
                },
                { position: "top-center" }
            );
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error adding service:', error);
        }
    };

    const onInputUpdate = (serviceTools) => {
        console.log('on input added', serviceTools);
        if (editService) {
            setEditService({ ...editService, tools: serviceTools });
        } else {
            setNewService({ ...newService, tools: serviceTools });
        }
    };

    const handleAddResource = async () => {
        if (!newResource.services || newResource.services.length === 0) {
            setServicesError(true);
            return;
        }
        setEditResource(null);
        setServicesError(false);
        let hasInvalidTimeSlot = false;
        const newErrors = { ...timeSlotErrors };
        let formattedWorkingHours = {};
        daysOfWeek.forEach(day => {
            newErrors[day] = {};
            const recurringSchedule = newResource.working_hours.recurring_schedules[0];
            const dayScheduleBlocks = recurringSchedule.schedule_blocks.filter(block => block.schedule_block.weekday === day);
            const validTimeSlots = dayScheduleBlocks.filter((block, index) => {
                const timeSlot = block.schedule_block;
                if (!timeSlot.start_time || !timeSlot.end_time) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time and end time are required.';
                    return false;
                }
                const startTime = new Date(`1970-01-01T${timeSlot.start_time}:00`).getTime();
                const endTime = new Date(`1970-01-01T${timeSlot.end_time}:00`).getTime();
                if (startTime >= endTime) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time must be before end time.';
                    return false;
                }
                return true;
            });
            formattedWorkingHours[day] = validTimeSlots.map(block => block.schedule_block);
        });
        if (hasInvalidTimeSlot) {
            setTimeSlotErrors(newErrors);
            return;
        }
        const formattedResource = {
            ...newResource,
            working_hours: newResource.working_hours,
            max_simultaneous_bookings: 1,
            services: newResource.services.map(service => ({ id: service.id, name: service.name }))
        };
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.post(`${process.env.REACT_APP_API_URL}/api/resources`, formattedResource, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    setResourceDialogOpen(false);
                    fetchResources();
                }),
                {
                    pending: 'Creating resource...',
                    success: 'Resource created successfully!',
                    error: 'Error creating resource',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error adding resource:', error);
        }
    };

    const handleEditResource = async () => {
        if (!editResource.services || editResource.services.length === 0) {
            setServicesError(true);
            return;
        }
        setServicesError(false);
        let hasInvalidTimeSlot = false;
        const newErrors = { ...timeSlotErrors };
        const recurringSchedule = editResource.working_hours.recurring_schedules[0];
        daysOfWeek.forEach(day => {
            newErrors[day] = {};
            const dayScheduleBlocks = recurringSchedule.schedule_blocks.filter(block => block.schedule_block.weekday === day);
            dayScheduleBlocks.forEach((block, index) => {
                const timeSlot = block.schedule_block;
                if (!timeSlot.start_time || !timeSlot.end_time) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time and end time are required.';
                    return;
                }
                const startMinutes = parseTime(timeSlot.start_time);
                const endMinutes = parseTime(timeSlot.end_time);
                if (startMinutes === null || endMinutes === null) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Invalid time format.';
                    return;
                }
                if (startMinutes >= endMinutes) {
                    hasInvalidTimeSlot = true;
                    newErrors[day][index] = 'Start time must be before end time.';
                    return;
                }
            });
        });
        if (hasInvalidTimeSlot) {
            setTimeSlotErrors(newErrors);
            return;
        } else {
            setTimeSlotErrors({});
        }
        const formattedResource = {
            ...editResource,
            services: editResource.services.map(service => ({ id: service.id, name: service.name })),
            working_hours: editResource.working_hours
        };
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.patch(`${process.env.REACT_APP_API_URL}/api/resources/${editResource.id}`, formattedResource, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    setResourceDialogOpen(false);
                    setEditResource(null);
                    fetchResources();
                }),
                {
                    pending: 'Updating resource...',
                    success: 'Resource updated successfully!',
                    error: 'Error updating resource',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error editing resource:', error);
        }
    };

    const handleEditLocation = async () => {
        const updatedLocation = {
            ...editLocation,
            timezone: editLocation.timezone.value,
        };
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.patch(`${process.env.REACT_APP_API_URL}/api/locations/${editLocation.id}`, updatedLocation, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    setLocationDialogOpen(false);
                    setEditLocation(null);
                    fetchLocations();
                }),
                {
                    pending: 'Updating timezone...',
                    success: 'Timezone updated successfully!',
                    error: 'Error updating timezone',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error editing location:', error);
        }
    };

    // Handle editing a service (include new flexible fields if type is flexible)
    const handleEditService = async () => {
        const duplicateService = services.find(
            (service) =>
                service.name.toLowerCase() === editService.name.toLowerCase() &&
                service.id !== editService.id
        );
        if (duplicateService) {
            toast.error('A service with the same name already exists.', { position: "top-center" });
            return;
        }
        const serviceType = editService.type;
        if (serviceType === 'fixed') {
            editService.min_duration_hours = null;
            editService.min_duration_minutes = null;
            editService.max_duration_hours = null;
            editService.max_duration_minutes = null;
            editService.duration_step_hours = null;
            editService.duration_step_minutes = null;
            editService.default_duration_hours = null;
            editService.default_duration_minutes = null;
        } else if (serviceType === 'flexible') {
            editService.duration_hours = null;
            editService.duration_minutes = null;
        }
        if (!validateMinCancellation(editService)) return;
        if (!validateServiceName(editService.name)) return;
        if (!validateServiceType(serviceType)) return;
        if (!validateDuration(editService)) return;
        const duration = convertToIsoDuration(editService.duration_hours, editService.duration_minutes);
        const min_duration = convertToIsoDuration(editService.min_duration_hours, editService.min_duration_minutes);
        const max_duration = convertToIsoDuration(editService.max_duration_hours, editService.max_duration_minutes);
        const cancellation_threshold = convertToIsoDuration(editService.cancellation_threshold_hours);
        let price = parseFloat(editService.price);
        if (!isNaN(price)) {
            price = price.toFixed(3);
        }
        const updatedService = {
            ...editService,
            duration,
            min_duration,
            max_duration,
            cancellation_threshold,
            duration_step: convertToIsoDuration(editService.duration_step_hours, editService.duration_step_minutes),
            default_duration: convertToIsoDuration(editService.default_duration_hours, editService.default_duration_minutes),
            bookable_interval: convertToIsoDuration(editService.bookable_interval_hours, editService.bookable_interval_minutes),
            booking_window_start: convertToIsoDuration(editService.booking_window_start_hours, editService.booking_window_start_minutes),
            booking_window_end: convertToIsoDurationDays(editService.booking_window_end_days, editService.booking_window_end_hours),
            buffer_time_before: convertToIsoDuration(editService.buffer_time_before_hours, editService.buffer_time_before_minutes),
            buffer_time_after: convertToIsoDuration(editService.buffer_time_after_hours, editService.buffer_time_after_minutes),
            ...(price && { price }),
            ...(editService.type === 'fixed' && { tools: [] }),
            // Include new fields in the payload
            booking_enabled: editService.booking_enabled,
            quote_enabled: editService.quote_enabled,
        };
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                (async () => {
                    await axios.put(`${process.env.REACT_APP_API_URL}/api/services/${editService.id}`, updatedService, {
                        headers: { Authorization: `${token}` },
                    });
                    setServiceDialogOpen(false);
                    await fetchServices();
                })(),
                {
                    pending: 'Updating service...',
                    success: 'Service updated successfully!',
                    error: 'Error updating service',
                },
                { position: "top-center" }
            );
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error editing service:', error);
        }
    };

    const handleDeleteResource = async (resourceId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/resources/${resourceId}`, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    fetchResources();
                }),
                {
                    pending: 'Deleting resource...',
                    success: 'Resource deleted successfully!',
                    error: 'Error deleting resource',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error deleting resource:', error);
        }
    };

    const handleDeleteLocation = async (locationId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/locations/${locationId}`, {
                    headers: { Authorization: `${token}` },
                }).then(response => {
                    fetchLocations();
                }),
                {
                    pending: 'Deleting timezone...',
                    success: 'Timezone deleted successfully!',
                    error: 'Error deleting timezone',
                },
                { position: "top-center" }
            );
        } catch (error) {
            console.error('Error deleting timezone:', error);
        }
    };
    const setBookingEnabledFunction = async (enabled) => {
        try {
            setBookingEnabled(enabled);
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.patch(`${process.env.REACT_APP_API_URL}/api/chatbots/${chatbotId}`, {
                    booking_enabled: enabled
                }, {
                    headers: { Authorization: token },
                }),
                {
                    pending: 'Updating booking status...',
                    success: 'Booking status updated successfully!',
                    error: 'Error updating booking status',
                },
                { position: "top-center" }
            );
        }
        catch (error) {
            console.error('Error updating booking status:', error);
        }
    };

    const handleDeleteService = async (serviceId) => {
        try {
            const token = localStorage.getItem('access_token');
            await toast.promise(
                axios.delete(`${process.env.REACT_APP_API_URL}/api/services/${serviceId}`, {
                    headers: { Authorization: `${token}` },
                }),
                {
                    pending: 'Deleting service...',
                    success: 'Service deleted successfully!',
                    error: 'Error deleting service',
                },
                { position: "top-center" }
            );
            await fetchServices();
            await handleSaveChatbot();
        } catch (error) {
            console.error('Error deleting service:', error);
        }
    };

    const openEditDialog = (resource) => {
        setEditResource(resource);
        setResourceDialogOpen(true);
    };
    const openEditServiceDialog = (service) => {
        setEditService(service);
        setServiceDialogOpen(true);
    };
    const openEditLocationDialog = (location) => {
        setEditLocation(location);
        setLocationDialogOpen(true);
    };
    const validateServiceName = (serviceName) => {
        if (!serviceName || serviceName.length > 100) {
            setServiceNameError(true);
            return false;
        }
        setServiceNameError(false);
        return true;
    };
    const validateServiceType = (serviceType) => {
        if (!serviceType) {
            setServiceTypeError(true);
            return false;
        }
        setServiceTypeError(false);
        return true;
    };
    const validateMinCancellation = (service) => {
        if (!service.cancellation_threshold_hours) {
            setMinCancellationError(true);
            return false;
        }
        setServiceTypeError(false);
        return true;
    };
    const validateDuration = (service) => {
        const isInvalid = (value) =>
            value === '' || value === null || value === undefined || value === '00';
        if (service.type === 'fixed') {
            if (isInvalid(service.duration_hours) && isInvalid(service.duration_minutes)) {
                setDurationError(true);
                return false;
            }
        } else if (service.type === 'flexible') {
            if (isInvalid(service.max_duration_hours) && isInvalid(service.max_duration_minutes)) {
                setDurationError(true);
                return false;
            }
            if (isInvalid(service.min_duration_hours) && isInvalid(service.min_duration_minutes)) {
                setMinDurationError(true);
                return false;
            }
            if (
                (Number(service.max_duration_hours) * 60) + Number(service.max_duration_minutes) <=
                (Number(service.min_duration_hours) * 60) + Number(service.min_duration_minutes)
            ) {
                setDurationError(true);
                return false;
            }
        }
        setDurationError(false);
        setMinCancellationError(false);
        setMinDurationError(false);
        return true;
    };

    const renderContent = () => {
        switch (activeSubtab) {
            case 'quoteRules':
                return (
                    <Box>
                        <Typography>
                            Provide clear instructions on how you'd like your quote for flexible services to be calculated using the data you've supplied.
                            <br />
                            <strong>Think about it as if you were going on holiday, and your co-worker would be responsible for providing the quote. How would you explain the quote process to them?</strong>
                            <br />
                            <strong>You should be clear on how the price and duration of the service must be calculated.</strong> You may also add additional files to support the quote calculation (e.g., an excel file containing the price per item)
                        </Typography>
                        <Box mb={2}>
                            <TextField
                                sx={{ backgroundColor: 'white' }}
                                value={quoteInstructions}
                                onChange={(e) => setQuoteInstructions(e.target.value)}
                                variant="outlined"
                                fullWidth
                                multiline
                                placeholder={`Example 1 (Removalist): Baseline price is $400 for any removalist job. 
If removal date is on the weekends or public holidays, add $100
If the origin location is greater than 30 kms from Perth CBD, then add $5 per additional kilometer.
If the destiny location is greater than 30kms from Perth CBD, add $5 per additional kilometer. 
Add $100 per number of bedrooms
If it’s an apartment without elevator, add $100.
If packing is required, add $200.
We only do removalist job within 500km of Perth CBD
`}
                                rows={10}
                            />
                            <Box mt={2}>
                                <Typography>
                                    Send quote details for your approval before sharing it with the customer
                                </Typography>
                                <Switch checked={quoteWhatsapp} onChange={(e) => setQuoteWhatsapp
                                    (e.target.checked)} />
                                {quoteWhatsapp && (
                                    <>
                                        <Typography>
                                            Provide a WhatsApp-enabled mobile number to approve the quotes calculated by the AI assistant.
                                            <br />
                                        </Typography>
                                        <PhoneInput
                                            placeholder="Enter phone number"
                                            defaultCountry="AU"
                                            value={mobile}
                                            onChange={setMobile}
                                            inputComponent={PhoneTextField}
                                            error={Boolean(mobile && !isPossiblePhoneNumber(mobile))}
                                            helperText={
                                                mobile
                                                    ? isPossiblePhoneNumber(mobile)
                                                        ? undefined
                                                        : 'Invalid phone number'
                                                    : 'Phone number required'
                                            }
                                        />
                                    </>
                                )}
                            </Box>
                            <Button
                                onClick={handleSaveChatbot}
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={isSaving}
                                sx={{ marginTop: '16px' }}
                                fullWidth={isMobile}
                            >
                                {isSaving ? <CircularProgress size={24} /> : 'Save'}
                            </Button>
                        </Box>
                        <FileUpload
                            label="Drag and drop your PDF, CSV, DOCX, or TXT file here, or click to select it. (20 MB limit)"
                            type="file"
                            multiple={true}
                            onSuccessfulUpload={handleSuccessfulUpload}
                            fileTypes={['PDF', 'DOCX', 'TXT', 'CSV']}
                        />
                        <Box mt={2} sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={files}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>File Name</TableCell>
                                        <TableCell>Characters</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, file) => (
                                    <>
                                        <TableCell> {file?.file_name}</TableCell>
                                        <TableCell>{file?.num_of_chars}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() =>
                                                    openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteFile(file.id))
                                                }
                                                startIcon={<DeleteIcon />}
                                                size={isMobile ? "small" : "medium"}
                                            >
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case "quoteInput":
                return <QuoteTab />;
            case 'services':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add below the services that you provide. For instance, if you run a clinic, you may provide "Short Appointment (fixed duration of 30 mins and $100) service" and "Long appointment (fixed duration of 1 hour and $180) service". On the other hand, if you are a removalist company, your service duration and price may be flexible based on the customer requirements.
                            <br />
                            Each service may have a fixed or flexible duration. Flexible services require inputs from the customer to be estimated properly.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setNewService({
                                    name: '',
                                    price: '',
                                    type: '',
                                    tools: [],
                                    duration_step_hours: '',
                                    duration_step_minutes: '',
                                    default_duration_hours: '',
                                    default_duration_minutes: '',
                                    bookable_interval_hours: '',
                                    bookable_interval_minutes: '',
                                    booking_window_start_hours: '',
                                    booking_window_start_minutes: '',
                                    booking_window_end_days: '',
                                    booking_window_end_hours: '',
                                    buffer_time_before_hours: '',
                                    buffer_time_before_minutes: '',
                                    buffer_time_after_hours: '',
                                    buffer_time_after_minutes: '',
                                    booking_enabled: true,
                                    quote_enabled: false,
                                });
                                setServiceDialogOpen(true);
                                setEditService(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Service
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={services}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Price</TableCell>
                                        <TableCell>Duration</TableCell>
                                        <TableCell>Min Duration</TableCell>
                                        <TableCell>Max Duration</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, service) => (
                                    <>
                                        <TableCell>{service.name}</TableCell>
                                        <TableCell>{service.type}</TableCell>
                                        <TableCell>
                                            {service.price && !isNaN(service.price)
                                                ? `$${parseFloat(service.price).toFixed(2)}`
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.duration
                                                ? `${convertFromIsoDuration(service.duration).hours}:${convertFromIsoDuration(service.duration).minutes}`
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.min_duration
                                                ? `${convertFromIsoDuration(service.min_duration).hours}:${convertFromIsoDuration(service.min_duration).minutes}`
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {service.max_duration
                                                ? `${convertFromIsoDuration(service.max_duration).hours}:${convertFromIsoDuration(service.max_duration).minutes}`
                                                : ''
                                            }
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditServiceDialog(service)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() =>
                                                        openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteService(service.id))
                                                    }
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case 'locations':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add below the timezone that you provide your services.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                setLocationDialogOpen(true);
                                setEditLocation(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Location
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={locations}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Timezone</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, location) => (
                                    <>
                                        <TableCell>{location.name}</TableCell>
                                        <TableCell>{location.timezone}</TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditLocationDialog(location)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() =>
                                                        openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteLocation(location.id))
                                                    }
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case 'resources':
                return (
                    <Box>
                        <Typography padding="1rem 0">
                            Add the resources that deliver your services. For example, if you run a clinic, <strong>each doctor is considered a resource</strong>. Similarly, if you offer removalist services, <strong>each truck you operate is a resource</strong>. For each resource, make sure to assign the relevant service they provide and specify their working hours.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => {
                                fetchServices();
                                setResourceDialogOpen(true);
                                setEditResource(null);
                            }}
                            sx={{ marginBottom: '16px' }}
                            fullWidth={isMobile}
                        >
                            Add Resource
                        </Button>
                        <Box sx={{ overflowX: 'auto' }}>
                            <TableVirtuoso
                                data={resources}
                                allowFullScreen
                                useWindowScroll
                                components={VirtuosoTableComponents}
                                fixedHeaderContent={() => (
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Services</TableCell>
                                        <TableCell>Working Hours</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                )}
                                itemContent={(index, resource) => (
                                    <>
                                        <TableCell>{resource.name}</TableCell>
                                        <TableCell>
                                            {resource.services.map((service, index) => (
                                                <Chip key={index} label={service.name} sx={{ margin: '2px' }} />
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {resource?.working_hours?.recurring_schedules?.map((schedule, schedIndex) =>
                                                schedule.schedule_blocks.map((block, blockIndex) => (
                                                    <Box key={`${schedIndex}-${blockIndex}`} mb={1}>
                                                        <strong>{block.schedule_block.weekday}:</strong> {block.schedule_block.start_time} - {block.schedule_block.end_time}
                                                    </Box>
                                                ))
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction="row" spacing={1}>
                                                <Button
                                                    variant="outlined"
                                                    onClick={() => openEditDialog(resource)}
                                                    startIcon={<EditIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={() =>
                                                        openConfirmDialog('Are you sure you want to delete this item? This action cannot be undone.', () => handleDeleteResource(resource.id))
                                                    }
                                                    startIcon={<DeleteIcon />}
                                                    size={isMobile ? "small" : "medium"}
                                                >
                                                    Delete
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </>
                                )}
                            />
                        </Box>
                    </Box>
                );
            case 'booking':
                return (

                    <Box fullWidth mt={2}>
                        {/* New: Booking logo upload */}
                        <Box mb={2}>
                            <Typography variant="h6">Booking Logo</Typography>
                            <input type="file" accept="image/*" onChange={handleBookingLogoFileChange} />
                            {bookingLogo && (
                                <Box mt={1}>
                                    <img src={bookingLogo} alt="Booking Logo" style={{ width: '100px', height: '100px', marginTop: '10px', borderRadius: '50%' }} />
                                    {bookingLogoRaw && (
                                        <Button variant="outlined" onClick={handleBookingLogoReCrop} sx={{ mt: 1 }}>
                                            Re-crop Booking Logo
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </Box>
                        {/* New: Booking confirmation email content */}
                        <Box mb={2}>
                            <Typography variant="h6">Booking Confirmation Email Content</Typography>
                            <TextField
                                value={bookingConfirmationEmailContent}
                                onChange={(e) => setBookingConfirmationEmailContent(e.target.value)}
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                placeholder="Enter the email content for booking confirmation..."
                            />
                        </Box>
                        <Typography variant="subtitle1">
                            Information to capture during booking. You may add additional fields to suit your needs.
                        </Typography>
                        <DndContext
                            sensors={sensors}
                            collisionDetection={closestCenter}
                            onDragEnd={(event) => {
                                const { active, over } = event;
                                if (active.id !== over?.id) {
                                    setFields((items) => {
                                        const oldIndex = items.findIndex((field) => field.name === active.id);
                                        const newIndex = items.findIndex((field) => field.name === over.id);
                                        return arrayMove(items, oldIndex, newIndex);
                                    });
                                }
                            }}
                        >
                            <SortableContext
                                items={fields.map(field => field.name)}
                                strategy={verticalListSortingStrategy}
                            >
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    freeSolo
                                    options={[]}
                                    getOptionLabel={(option) => option.name || option}
                                    value={fields}
                                    onChange={(event, newValue, reason) => {
                                        if (reason === 'remove-option') {
                                            setFields(newValue.map(field => ({ name: field.name || field, mandatory: field.mandatory || false })));
                                        } else if (typeof newValue === 'string') {
                                            if (!fields.find(f => f.name === newValue)) {
                                                setFields([...fields, { name: newValue, mandatory: false }]);
                                            }
                                        } else if (newValue && newValue.inputValue) {
                                            if (!fields.find(f => f.name === newValue.inputValue)) {
                                                setFields([...fields, { name: newValue.inputValue, mandatory: false }]);
                                            }
                                        } else {
                                            const addedFields = newValue.filter(option => typeof option === 'string' && !fields.find(f => f.name === option));
                                            const formattedFields = addedFields.map(fieldName => ({ name: fieldName, mandatory: false }));
                                            setFields([...fields, ...formattedFields]);
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = createFilterOptions()(options, params);
                                        const { inputValue } = params;
                                        const isExisting = fields.some((field) => inputValue === field.name);
                                        if (inputValue !== '' && !isExisting) {
                                            filtered.push(inputValue);
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    renderOption={(props, option) => (
                                        <li {...props}>
                                            {typeof option === 'string' ? option : option.name}
                                        </li>
                                    )}
                                    renderTags={() => null}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Add new fields" />
                                    )}
                                />
                                <List>
                                    {fields.map((field) => (
                                        <SortableListItem
                                            key={field.name}
                                            field={field}
                                            onDelete={(name) => setFields(fields.filter(f => f.name !== name))}
                                            onToggleMandatory={(name) => {
                                                setFields(fields.map((f) =>
                                                    f.name === name ? { ...f, mandatory: !f.mandatory } : f
                                                ));
                                            }}
                                        />
                                    ))}
                                </List>
                            </SortableContext>
                        </DndContext>
                        <Button variant="contained" color="primary" onClick={handleSaveLeadsConfig}>Save</Button>
                    </Box>
                );

            default:
                return null;
        }
    };

    const renderWorkingHoursInputs = () => {
        const resource = editResource ? editResource : newResource;
        const recurringSchedules = resource.working_hours?.recurring_schedules?.[0]?.schedule_blocks || [];
        const groupedByDay = daysOfWeek.reduce((acc, day) => {
            acc[day] = recurringSchedules.filter(block => block.schedule_block.weekday === day);
            return acc;
        }, {});
        return daysOfWeek.map((day) => (
            <Box key={day} marginBottom={2}>
                <Typography variant="subtitle1" fontWeight="bold">{day}</Typography>
                {groupedByDay[day].length > 0 &&
                    groupedByDay[day].map((block, index) => (
                        <Stack key={index} direction={isMobile ? "column" : "row"} spacing={1} alignItems="center" marginBottom={1}>
                            <TextField
                                label="Start Time"
                                type="time"
                                value={block.schedule_block.start_time || ''}
                                onChange={(e) => handleWorkingHoursChange(day, index, 'start_time', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                                margin="dense"
                                error={Boolean(timeSlotErrors[day] && timeSlotErrors[day][index])}
                                fullWidth={isMobile}
                            />
                            <TextField
                                label="End Time"
                                type="time"
                                value={block.schedule_block.end_time || ''}
                                onChange={(e) => handleWorkingHoursChange(day, index, 'end_time', e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ step: 300 }}
                                margin="dense"
                                error={Boolean(timeSlotErrors[day] && timeSlotErrors[day][index])}
                                fullWidth={isMobile}
                            />
                            <IconButton onClick={() => handleRemoveTimeSlot(day, index)} size={isMobile ? "small" : "medium"}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                    ))
                }
                <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={() => handleAddTimeSlot(day)}
                    fullWidth={isMobile}
                >
                    Add Time Slot
                </Button>
                {timeSlotErrors[day] && Object.keys(timeSlotErrors[day]).length > 0 &&
                    Object.entries(timeSlotErrors[day]).map(([index, error]) => (
                        <Typography key={index} variant="caption" color="error" align="left">
                            {error}
                        </Typography>
                    ))
                }
            </Box>
        ));
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Typography variant="h4" fontWeight="700" padding="1rem 0">Quote & Bookings Settings</Typography>
            <Box display={'flex'} flexDirection={'row'} alignItems={"center"}>
                <Typography variant="subtitle1">Allow Virtual Assistant to make bookings</Typography>
                <Tooltip title="Enable or disable the virtual assistant's ability to provide the Calendar URL for customers to book appointments and check availability.">
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
                <Switch checked={bookingEnabled} onChange={(e) => setBookingEnabledFunction(e.target.checked)} />
            </Box>
            <Box flex={1} display={'flex'} flexDirection={'row'} alignItems={"center"} component="form" onSubmit={(event) => { event.preventDefault(); handleSaveClick(); }} >
                <Typography variant="subtitle1">Calendar URL</Typography>
                <Tooltip title="Whenever the user enquires about availability or wishes to make a booking, the virtual assistant will provide them with this URL.">
                    <IconButton>
                        <HelpOutlineIcon />
                    </IconButton>
                </Tooltip>
                <TextField
                    value={calendarURL}
                    onChange={(e) => setCalendarURL(e.target.value)}
                    variant="outlined"

                    sx={{
                        backgroundColor: 'white',
                        width: '700px',
                        marginLeft: '20px',
                        marginRight: '20px'

                    }}
                />
                <Button type="submit" variant="contained" color="primary" >
                    Save
                </Button>
            </Box>
            <Tabs
                value={activeSubtab}
                onChange={(e, val) => setActiveSubtab(val)}
                aria-label="booking subtabs"
                variant={isMobile ? "fullWidth" : "standard"}
                scrollButtons={isMobile ? "on" : "off"}
                visibleScrollbar
                allowScrollButtonsMobile
                sx={{ borderBottom: 1, borderColor: 'divider', width: '96%' }}
            >
                <Tab value="services" label="Services" id="services" sx={{ minWidth: isMobile ? 60 : 120, padding: isMobile ? '6px 8px' : '12px 16px', textTransform: 'none', fontSize: isMobile ? '0.875rem' : '1rem' }} />
                {hasFlexibleService &&
                    <Tab value="quoteRules" label="How to Precify" id="quoteRules" sx={{ minWidth: isMobile ? 60 : 120, padding: isMobile ? '6px 8px' : '12px 16px', textTransform: 'none', fontSize: isMobile ? '0.875rem' : '1rem' }} />}
                <Tab value="locations" label="Timezone" id="locations" sx={{ minWidth: isMobile ? 60 : 120, padding: isMobile ? '6px 8px' : '12px 16px', textTransform: 'none', fontSize: isMobile ? '0.875rem' : '1rem' }} />
                <Tab value="resources" label="Resources" id="resources" sx={{ minWidth: isMobile ? 60 : 120, padding: isMobile ? '6px 8px' : '12px 16px', textTransform: 'none', fontSize: isMobile ? '0.875rem' : '1rem' }} />
                <Tab value="booking" label="Booking" id="booking" sx={{ minWidth: isMobile ? 60 : 120, padding: isMobile ? '6px 8px' : '12px 16px', textTransform: 'none', fontSize: isMobile ? '0.875rem' : '1rem' }} />

            </Tabs>
            <Paper square={false} elevation={4} sx={{ padding: isMobile ? "1rem" : "0.5rem", margin: '0', width: '95%' }}>
                {isFetching ? <Spinner /> : renderContent()}
            </Paper>

            {/* Service Dialog */}
            <Dialog fullScreen={isMobile} fullWidth maxWidth="lg" open={serviceDialogOpen} onClose={() => setServiceDialogOpen(false)}>
                <DialogTitle>{editService ? 'Edit Service' : 'Add Service'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Service Name */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <TextField
                                    label='Service Name'
                                    placeholder="Your Service Name e.g. Removalist Job, Short Appointment (30mins), Carpet Cleaning"
                                    InputLabelProps={{ shrink: true }}
                                    value={editService ? editService.name : newService.name}
                                    onChange={(e) =>
                                        editService
                                            ? setEditService({ ...editService, name: e.target.value })
                                            : setNewService({ ...newService, name: e.target.value })
                                    }
                                    fullWidth
                                    required
                                    margin="dense"
                                    error={serviceNameError}
                                    helperText={serviceNameError ? 'Service name is required and must be under 100 digits' : ''}
                                />
                                <Tooltip title="The name of the service.">
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* Type */}
                        <Grid item xs={12}>
                            <Box display="flex" alignItems="center">
                                <FormControl fullWidth margin="dense" disabled={selectedTab === 'resources'} error={serviceTypeError}>
                                    <InputLabel id="service-type-label">Type</InputLabel>
                                    <Select
                                        value={editService ? editService.type : newService.type}
                                        onChange={(e) =>
                                            editService
                                                ? setEditService({ ...editService, type: e.target.value })
                                                : setNewService({ ...newService, type: e.target.value })
                                        }
                                        label="Type"
                                        InputLabelProps={{ shrink: true }}
                                        required
                                    >
                                        <MenuItem value="fixed">Fixed</MenuItem>
                                        <MenuItem value="flexible">Flexible</MenuItem>
                                    </Select>
                                    <FormHelperText>Please fill the service type</FormHelperText>
                                </FormControl>
                                <Tooltip title="The type of the service. Fixed is for services with a set duration; flexible requires customer input for duration.">
                                    <IconButton>
                                        <HelpOutlineIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        {/* New Switches for Booking and Quote Enabled */}
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={editService ? editService.booking_enabled : newService.booking_enabled}
                                        onChange={(e) => {
                                            if (editService) {
                                                setEditService({ ...editService, booking_enabled: e.target.checked });
                                            } else {
                                                setNewService({ ...newService, booking_enabled: e.target.checked });
                                            }
                                        }}
                                    />
                                }
                                label="Booking Enabled"
                            />
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={editService ? editService.quote_enabled : newService.quote_enabled}
                                        onChange={(e) => {
                                            if (editService) {
                                                setEditService({ ...editService, quote_enabled: e.target.checked });
                                            } else {
                                                setNewService({ ...newService, quote_enabled: e.target.checked });
                                            }
                                        }}
                                    />
                                }
                                label="Quote Enabled"
                            />
                        </Grid>

                        {/* Conditional Fields – render only if booking is enabled */}
                        {(editService ? editService.booking_enabled : newService.booking_enabled) && (
                            editService ? (
                                editService.type === 'flexible' ? (
                                    <>
                                        {/* Hourly Rate */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    label="Hourly Rate"
                                                    value={editService.price}
                                                    placeholder="100.00"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setEditService({ ...editService, price: e.target.value })}
                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                                    fullWidth
                                                    margin="dense"
                                                />
                                                <Tooltip title="For flexible services, this is the hourly rate.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Min Duration */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Min Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.min_duration_hours}
                                                    onChange={(e) => setEditService({ ...editService, min_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                    error={minDurationError}
                                                    helperText={minDurationError ? 'Fill minimum duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Min Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.min_duration_minutes}
                                                    onChange={(e) => setEditService({ ...editService, min_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The minimum duration allowed for booking the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Max Duration */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Max Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.max_duration_hours}
                                                    onChange={(e) => setEditService({ ...editService, max_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                    error={durationError}
                                                    helperText={durationError ? 'Fill max duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Max Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.max_duration_minutes}
                                                    onChange={(e) => setEditService({ ...editService, max_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The maximum duration allowed for booking the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Cancellation Threshold */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Minimum Notice for Cancellation (Hours)"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.cancellation_threshold_hours}
                                                    onChange={(e) => setEditService({ ...editService, cancellation_threshold_hours: e.target.value })}
                                                    fullWidth
                                                    required
                                                    margin="dense"
                                                    error={minCancellationError}
                                                    helperText={minCancellationError ? 'Fill minimum notice for cancellation' : ''}
                                                />
                                                <Tooltip title="Time required before booking start to allow cancellation">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Additional Flexible Fields */}
                                        {/* Duration Step */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Duration Step (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.duration_step_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, duration_step_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Duration Step (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.duration_step_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, duration_step_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The duration step allowed for the service (flexible only).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Default Duration */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Default Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.default_duration_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, default_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Default Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.default_duration_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, default_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The default duration for the service (flexible only).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Bookable Interval */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Bookable Interval (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.bookable_interval_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, bookable_interval_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Bookable Interval (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.bookable_interval_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, bookable_interval_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Slot interval for bookings (e.g., 15 minutes).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        {/* Buffer Time Before */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time Before (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_before_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_before_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time Before (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_before_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_before_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required before the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time After */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time After (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_after_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_after_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time After (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_after_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_after_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required after the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        {/* Booking Window Start */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window Start (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_start_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_start_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window Start (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_start_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_start_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Minimum time between now and booking start.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window End */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window End (Days)"
                                                    type="number"
                                                    placeholder="Days"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_end_days || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_end_days: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window End (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_end_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_end_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <Tooltip title="Maximum advance time a booking can be created (days/hours).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>


                                    </>
                                ) : (
                                    <>
                                        {/* Fixed Service Fields */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    label="Price"
                                                    value={editService.price}
                                                    placeholder="100.00"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setEditService({ ...editService, price: e.target.value })}
                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                                    fullWidth
                                                    margin="dense"
                                                />
                                                <Tooltip title="For fixed services, this is a fixed price.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    value={editService.duration_hours}
                                                    onChange={(e) => setEditService({ ...editService, duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    error={durationError}
                                                    helperText={durationError ? 'Fill duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    value={editService.duration_minutes}
                                                    onChange={(e) => setEditService({ ...editService, duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The duration for the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Cancellation Threshold */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Minimum Notice for Cancellation (Hours)"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.cancellation_threshold_hours}
                                                    onChange={(e) => setEditService({ ...editService, cancellation_threshold_hours: e.target.value })}
                                                    fullWidth
                                                    required
                                                    margin="dense"
                                                    error={minCancellationError}
                                                    helperText={minCancellationError ? 'Fill minimum notice for cancellation' : ''}
                                                />
                                                <Tooltip title="Time required before booking start to allow cancellation">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>




                                        {/* Bookable Interval */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Bookable Interval (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.bookable_interval_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, bookable_interval_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Bookable Interval (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.bookable_interval_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, bookable_interval_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Slot interval for bookings (e.g., 15 minutes).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        {/* Buffer Time Before */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time Before (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_before_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_before_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time Before (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_before_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_before_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required before the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time After */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time After (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_after_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_after_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time After (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.buffer_time_after_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, buffer_time_after_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required after the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        {/* Booking Window Start */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window Start (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_start_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_start_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window Start (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_start_minutes || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_start_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Minimum time between now and booking start.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window End */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window End (Days)"
                                                    type="number"
                                                    placeholder="Days"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_end_days || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_end_days: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window End (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={editService.booking_window_end_hours || ''}
                                                    onChange={(e) => setEditService({ ...editService, booking_window_end_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <Tooltip title="Maximum advance time a booking can be created (days/hours).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                    </>
                                )
                            ) : (
                                newService.type === 'flexible' ? (
                                    <>
                                        {/* New Flexible Service Fields */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    label="Hourly Rate"
                                                    value={newService.price}
                                                    placeholder="100.00"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setNewService({ ...newService, price: e.target.value })}
                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                                    fullWidth
                                                    margin="dense"
                                                />
                                                <Tooltip title="For flexible services, this is the hourly rate.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Min Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.min_duration_hours}
                                                    onChange={(e) => setNewService({ ...newService, min_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                    error={minDurationError}
                                                    helperText={minDurationError ? 'Fill minimum duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Min Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.min_duration_minutes}
                                                    onChange={(e) => setNewService({ ...newService, min_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Minimum duration for booking the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Max Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.max_duration_hours}
                                                    onChange={(e) => setNewService({ ...newService, max_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                    error={durationError}
                                                    helperText={durationError ? 'Fill max duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Max Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.max_duration_minutes}
                                                    onChange={(e) => setNewService({ ...newService, max_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Maximum duration for booking the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Minimum Notice for Cancellation (Hours)"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.cancellation_threshold_hours}
                                                    onChange={(e) => setNewService({ ...newService, cancellation_threshold_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    disabled={selectedTab === 'resources'}
                                                />
                                                <Tooltip title="Time required before booking to allow cancellation">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* New Flexible Additional Fields */}
                                        {/* Duration Step */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Duration Step (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.duration_step_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, duration_step_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Duration Step (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.duration_step_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, duration_step_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The duration step allowed for the service (flexible only).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Default Duration */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Default Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.default_duration_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, default_duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Default Duration (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.default_duration_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, default_duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The default duration for the service (flexible only).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Bookable Interval */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Bookable Interval (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.bookable_interval_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, bookable_interval_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Bookable Interval (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.bookable_interval_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, bookable_interval_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Slot interval for bookings (e.g., 15 minutes).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time Before */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time Before (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_before_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_before_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time Before (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_before_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_before_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required before the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time After */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time After (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_after_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_after_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time After (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_after_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_after_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required after the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window Start */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window Start (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_start_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_start_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window Start (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_start_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_start_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Minimum time between now and booking start.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window End */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window End (Days)"
                                                    type="number"
                                                    placeholder="Days"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_end_days || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_end_days: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window End (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_end_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_end_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <Tooltip title="Maximum time into the future a booking can be created (days/hours).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>


                                    </>
                                ) : (
                                    <>
                                        {/* Fixed Service Fields */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center">
                                                <TextField
                                                    label="Price"
                                                    value={newService.price}
                                                    placeholder="100.00"
                                                    InputLabelProps={{ shrink: true }}
                                                    onChange={(e) => setNewService({ ...newService, price: e.target.value })}
                                                    InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                                                    fullWidth
                                                    margin="dense"
                                                    disabled={selectedTab === 'resources'}
                                                />
                                                <Tooltip title="For fixed services, this is a fixed price.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Duration (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    value={newService.duration_hours}
                                                    onChange={(e) => setNewService({ ...newService, duration_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    error={durationError}
                                                    helperText={durationError ? 'Fill duration in hours or minutes' : ''}
                                                />
                                                <TextField
                                                    label="Duration (Minutes)"
                                                    placeholder='Minutes'
                                                    type="number"
                                                    value={newService.duration_minutes}
                                                    onChange={(e) => setNewService({ ...newService, duration_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    disabled={selectedTab === 'resources'}
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="The duration for the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Minimum Notice for Cancellation (Hours)"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.cancellation_threshold_hours}
                                                    onChange={(e) => setNewService({ ...newService, cancellation_threshold_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    disabled={selectedTab === 'resources'}
                                                />
                                                <Tooltip title="Time required before booking to allow cancellation">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Bookable Interval */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Bookable Interval (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.bookable_interval_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, bookable_interval_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Bookable Interval (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.bookable_interval_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, bookable_interval_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Slot interval for bookings (e.g., 15 minutes).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time Before */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time Before (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_before_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_before_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time Before (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_before_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_before_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required before the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Buffer Time After */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Buffer Time After (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_after_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_after_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Buffer Time After (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.buffer_time_after_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, buffer_time_after_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Buffer time required after the service.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window Start */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window Start (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_start_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_start_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window Start (Minutes)"
                                                    type="number"
                                                    placeholder="Minutes"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_start_minutes || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_start_minutes: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0, max: 59 }}
                                                />
                                                <Tooltip title="Minimum time between now and booking start.">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                        {/* Booking Window End */}
                                        <Grid item xs={12} sm={6}>
                                            <Box display="flex" alignItems="center" gap={1}>
                                                <TextField
                                                    label="Booking Window End (Days)"
                                                    type="number"
                                                    placeholder="Days"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_end_days || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_end_days: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <TextField
                                                    label="Booking Window End (Hours)"
                                                    type="number"
                                                    placeholder="Hours"
                                                    InputLabelProps={{ shrink: true }}
                                                    value={newService.booking_window_end_hours || ''}
                                                    onChange={(e) => setNewService({ ...newService, booking_window_end_hours: e.target.value })}
                                                    fullWidth
                                                    margin="dense"
                                                    inputProps={{ min: 0 }}
                                                />
                                                <Tooltip title="Maximum time into the future a booking can be created (days/hours).">
                                                    <IconButton>
                                                        <HelpOutlineIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </Box>
                                        </Grid>

                                    </>
                                )
                            )
                        )}

                        {/* Service Quote Component – only render if service is flexible AND quote_enabled is true */}
                        {(editService
                            ? (editService.quote_enabled && editService.type === 'flexible')
                            : (newService.quote_enabled && newService.type === 'flexible')) && (
                                <Grid item xs={12}>
                                    <ServiceQuote service={editService ? editService : newService} onInputUpdate={onInputUpdate} />
                                </Grid>
                            )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setServiceDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editService ? handleEditService : handleAddService} color="primary" size={isMobile ? "small" : "medium"}>
                        {editService ? 'Save Changes' : 'Add Service'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Confirm Dialog */}
            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} fullScreen={isMobile}>
                <DialogTitle>Confirm Action</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogMessage}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={() => { onConfirm(); setConfirmDialogOpen(false); }} color="error" size={isMobile ? "small" : "medium"}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Resource Dialog */}
            <Dialog fullScreen={isMobile} fullWidth open={resourceDialogOpen} onClose={() => setResourceDialogOpen(false)}>
                <DialogTitle>{editResource ? 'Edit Resource' : 'Add Resource'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Resource Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="Resource Name"
                                placeholder="Resource Name"
                                InputLabelProps={{ shrink: true }}
                                value={editResource ? editResource.name : newResource.name}
                                onChange={(e) => editResource ? setEditResource({ ...editResource, name: e.target.value }) : setNewResource({ ...newResource, name: e.target.value })}
                                fullWidth
                                margin="dense"
                            />
                        </Grid>

                        {/* Services Selection */}
                        <Grid item xs={12}>
                            <CustomSelect
                                styles={customStyles}
                                isMulti
                                menuPortalTarget={document.body}
                                options={services}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                value={editResource ? editResource.services : newResource.services}
                                onChange={(selectedOptions) => {
                                    setServicesError(false);
                                    const selectedServices = selectedOptions ? selectedOptions.map((service) => ({ id: service.id, name: service.name })) : [];
                                    if (editResource) {
                                        setEditResource({ ...editResource, services: selectedServices });
                                    } else {
                                        setNewResource({ ...newResource, services: selectedServices });
                                    }
                                }}
                                placeholder="Select services"
                                className="custom-select"
                            />
                            {servicesError && (
                                <Typography variant="caption" color="error">
                                    At least one service must be selected.
                                </Typography>
                            )}
                        </Grid>

                        {/* Working Hours */}
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" fontWeight="bold">Working Hours</Typography>
                            {renderWorkingHoursInputs()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setResourceDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editResource ? handleEditResource : handleAddResource} color="primary" size={isMobile ? "small" : "medium"}>
                        {editResource ? 'Save Changes' : 'Add Resource'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Location Dialog */}
            <Dialog fullScreen={isMobile} fullWidth open={locationDialogOpen} onClose={() => setLocationDialogOpen(false)}>
                <DialogTitle>{editLocation ? 'Edit Timezone' : 'Add Timezone'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {/* Location Name */}
                        <Grid item xs={12}>
                            <TextField
                                label="Location Name"
                                placeholder="Location Name"
                                InputLabelProps={{ shrink: true }}
                                value={editLocation ? editLocation.name : newLocation.name}
                                onChange={(e) => editLocation ? setEditLocation({ ...editLocation, name: e.target.value }) : setNewLocation({ ...newLocation, name: e.target.value })}
                                fullWidth
                                margin="dense"
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>

                        {/* Timezone Selection */}
                        <Grid item xs={12}>
                            <TimezoneSelect
                                value={editLocation ? editLocation.timezone : newLocation.timezone}
                                onChange={(newValue) => {
                                    if (editLocation) {
                                        setEditLocation({ ...editLocation, timezone: newValue });
                                    } else {
                                        setNewLocation({ ...newLocation, timezone: newValue });
                                    }
                                }}
                                menuPortalTarget={document.body}
                                styles={{
                                    container: (provided) => ({ ...provided, width: '100%', marginTop: theme.spacing(1) }),
                                    control: (provided, state) => ({
                                        ...provided,
                                        width: '100%',
                                        minHeight: '56px',
                                        height: 'auto',
                                        borderColor: state.isFocused ? theme.palette.primary.main : theme.palette.divider,
                                        '&:hover': { borderColor: theme.palette.text.primary },
                                        boxShadow: 'none',
                                    }),
                                    placeholder: (provided) => ({ ...provided, color: theme.palette.text.secondary }),
                                    singleValue: (provided) => ({ ...provided, color: theme.palette.text.primary }),
                                    menuPortal: (base) => ({ ...base, zIndex: 1350 }),
                                }}
                                theme={(selectTheme) => ({
                                    ...selectTheme,
                                    colors: { ...selectTheme.colors, primary: theme.palette.primary.main },
                                })}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setLocationDialogOpen(false)} size={isMobile ? "small" : "medium"}>Cancel</Button>
                    <Button onClick={editLocation ? handleEditLocation : handleAddLocation} color="primary" size={isMobile ? "small" : "medium"}>
                        {editLocation ? 'Save Changes' : 'Add Location'}
                    </Button>
                </DialogActions>
            </Dialog>
               {openBookingLogoCropDialog && (
                            <Dialog open={openBookingLogoCropDialog} onClose={handleBookingLogoCropCancel} maxWidth="sm" fullWidth>
                                <DialogTitle>Crop Booking Logo</DialogTitle>
                                <DialogContent>
                                    <div style={{ position: 'relative', width: '100%', height: 400, background: '#333' }}>
                                        <Cropper
                                            image={bookingLogoSelectedImage}
                                            crop={bookingLogoCrop}
                                            zoom={bookingLogoZoom}
                                            aspect={1}
                                            onCropChange={setBookingLogoCrop}
                                            onCropComplete={(croppedArea, croppedAreaPixels) =>
                                                setBookingLogoCroppedAreaPixels(croppedAreaPixels)
                                            }
                                            onZoomChange={setBookingLogoZoom}
                                        />
                                    </div>
                                    <div style={{ marginTop: '1rem' }}>
                                        <Typography variant="caption">Zoom</Typography>
                                        <Slider
                                            value={bookingLogoZoom}
                                            min={1}
                                            max={3}
                                            step={0.1}
                                            onChange={(e, value) => setBookingLogoZoom(value)}
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleBookingLogoCropCancel}>Cancel</Button>
                                    <Button onClick={handleBookingLogoCropConfirm} variant="contained" color="primary">Crop &amp; Save</Button>
                                </DialogActions>
                            </Dialog>
                        )}
            
        </Box>
    );
};

export default BookingTab;
