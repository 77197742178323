import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@mui/material';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import loaderGif from '../images/loader.gif'; // Update with your loader gif path
import { toast } from 'react-toastify';

const ConnectMicrosoftCalendar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams(); // Extract chatbotId from URL parameters
  const [isLoading, setIsLoading] = useState(false);
  const hasHandledCode = useRef(false); // flag to ensure one-time execution

  // Your Microsoft app credentials (from Azure AD)
  const clientId = '3bf536c7-fd76-4121-8e49-82cc9fc75782';
  // Replace with your actual redirect URI which will handle the OAuth callback
  const redirectUri = encodeURIComponent(`${process.env.REACT_APP_URL}`);
  // Scope for accessing the user's calendar (include offline_access if you need a refresh token)
  const scope = encodeURIComponent('offline_access Calendars.ReadWrite');
  const responseType = 'code';
  // Response mode – typically "query"
  const responseMode = 'query';
  // Optional state parameter for CSRF protection
  const state = encodeURIComponent('microsoft');
  // Optional prompt parameter if you want to force consent
  const prompt = 'consent';

  // Construct the Microsoft OAuth URL
  const microsoftAuthUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&response_mode=${responseMode}&scope=${scope}&prompt=${prompt}&state=${state}`;

  const handleConnect = () => {
    // Redirect the user to Microsoft OAuth screen
    window.location.href = microsoftAuthUrl;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state')

    if (state == 'microsoft' && code && !hasHandledCode.current) {
      hasHandledCode.current = true;
      handleMicrosoftOAuthCode(code);
      // Remove code parameter from URL after handling it
      urlParams.delete('code');
      navigate({ search: urlParams.toString() }, { replace: true });
    }
  }, [location, navigate]);

  const handleMicrosoftOAuthCode = (msCode) => {
    setIsLoading(true);
    const loadingToastId = toast.loading("Connecting to Microsoft Calendar", { position: "top-center" });

    const token = localStorage.getItem('access_token');
    fetch(`${process.env.REACT_APP_API_URL}/api/microsoftOauth2callback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      body: JSON.stringify({ code: msCode, chatbot_id: id }),
    })
      .then(response => response.json())
      .then(data => {
        toast.dismiss(loadingToastId);

        if (data.success) {
          console.log('success');
          toast.success('Microsoft Calendar Connected', { position: "top-center" });
        } else {
          toast.error('Error - Microsoft Calendar Not Connected', { position: "top-center" });
          throw new Error('Failed to update Microsoft token');
        }
      })
      .catch(error => {
        toast.dismiss(loadingToastId);

        console.error(`Error updating Microsoft token: ${error.message}`);
        toast.error(`Error updating Microsoft token: ${error.message}`, { position: "top-center" });
      })
      .finally(() => {
        toast.dismiss(loadingToastId);

        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <img src={loaderGif} alt="Loading" className="loading-gif" />
      )}
      <Button variant="contained" color="primary" onClick={handleConnect}>
        Connect to Microsoft Calendar
      </Button>
    </>
  );
};

export default ConnectMicrosoftCalendar;
