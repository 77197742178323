import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import {
  Box,
  Typography,
} from '@mui/material';
const TestChatbotTab = () => {
  const { id } = useParams();

  useEffect(() => {
    window.embeddedChatbotConfig = {
      chatbotId: id
    };

    // Function to dynamically load the script
    const loadScript = (src, onLoad) => {
      const script = document.createElement('script');
      script.src = src;
      script.defer = true;
      script.onload = onLoad;
      document.body.appendChild(script);
    };

    // Ensure the script runs after the DOM is ready
    const checkAndLoadScript = () => {
      const container = document.getElementById('aissie-chat');
      if (container) {
        // Load the chat widget bundle
        loadScript('https://www.aissie.com.au/chat-widget-bundle.js', () => {
          console.log("Chat widget bundle loaded");
          if (!container._reactRootContainer) {
            const root = ReactDOM.createRoot(container);
            // Add your React rendering logic here, e.g.,
            // root.render(<ChatComponent />);
            container._reactRootContainer = root;
          } else {
            // Add your React rendering logic here, e.g.,
            // container._reactRootContainer.render(<ChatComponent />);
          }
        });
      } else {
        // Retry after a short delay if the container is not found
        setTimeout(checkAndLoadScript, 1000);
      }
    };

    checkAndLoadScript();
  }, [id]);

  return (
    <Box display="flex" style={{ maxWidth: "60vw" }} gap={4}>
      <Box flex={1} >
        <Typography variant="h4" fontWeight="700" padding="1rem 0">Test your Chatbot</Typography>
        <Typography fontWeight="400" marginBottom={"1rem"}>You can test your chatbot here. <b>Ensure you have uploaded your knowledge base prior to testing it.</b></Typography>

        <div id="aissie-chat" style={{ fontFamily: "Poppins" }} />;
      </Box>
    </Box>
  );
};

export default TestChatbotTab;
