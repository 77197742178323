import React, { useEffect, useState, useCallback } from 'react';
import '../css/Chatbot.css'; // Importing CSS
import axios from 'axios';
import Spinner from '../components/Spinner'; // Assuming you have a Spinner component
import ErrorMessage from '../components/ErrorMessage'; // Assuming you have an ErrorMessage component
import { useNavigate } from 'react-router-dom';

const Chatbots = () => {
  const [chatbots, setChatbots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/; Secure; SameSite=Strict";
  };

  const fetchChatbots = useCallback(async () => {
    const token = localStorage.getItem('access_token'); // Assuming the token is stored in localStorage
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code'); // Get the 'code' parameter from the URL
    const state = urlParams.get('state'); // Get the 'state' parameter from the URL
    try {
      setLoading(true);
      if (token) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/chatbots`, {
          headers: {
            Authorization: token,
          },
        });

        const chatbotsList = response.data.chatbots_list;
        setChatbots(chatbotsList);
        setLoading(false);

        if (chatbotsList.length === 0) {
          handleNewChatbot();
        } else {
          const chatbotIdFromCookie = document.cookie.split('; ').find(row => row.startsWith('aissie_chatbot_id='))?.split('=')[1];
          const matchingChatbot = chatbotsList.find(chatbot => chatbot.id === chatbotIdFromCookie);

          if (matchingChatbot) {
            navigate(`/chatbots/${matchingChatbot.id}${code ? `?code=${code}` : ''}${state ? `&state=${state}` : ''}`);
          } else {
            setCookie('aissie_chatbot_id', chatbotsList[0].id, 365 * 10); // Set cookie for 10 years
            navigate(`/chatbots/${chatbotsList[0].id}${code ? `?code=${code}` : ''}${state ? `&state=${state}` : ''}`);
          }
        }
      }
      else {
        navigate('/login');
      }
    } catch (error) {
      // Call the logout API endpoint
      localStorage.removeItem('access_token');
      navigate('/login');
      setLoading(false);

    }
  }, [navigate]);

  const handleNewChatbot = async () => {
    try {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('access_token');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/chatbots`, {}, {
        headers: {
          Authorization: token,
        },
      });

      const newChatbot = response.data;
      setCookie('aissie_chatbot_id', newChatbot.chatbot_id, 365 * 10); // Set cookie for 10 years
      navigate(`/chatbots/${newChatbot.chatbot_id}`);
      //fetchChatbots(); // Refresh chatbots list after creating a new one
    } catch (error) {
      setError('Error creating new AI assistant.');
      localStorage.removeItem('access_token');
      navigate('/login'); 
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChatbots();
  }, [fetchChatbots]);

  return (
    <div className="chatbots-page" style={{ backgroundColor: 'lightgrey', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      {loading ? (
        <div style={{ textAlign: 'center' }}>
          <Spinner />
          <p>Loading your configuration...</p>
        </div>
      ) : (
        <div>
          {error && <ErrorMessage message={error} />}
        </div>
      )}
    </div>
  );
};

export default Chatbots;