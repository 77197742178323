// Connect.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FacebookIcon from '@mui/icons-material/Facebook';
import axios from 'axios';
import loaderGif from '../images/loader.gif'; // Add the path to your loader gif
import './Connect.css';
import { toast } from 'react-toastify';

const Connect = ({ details = [] }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const { id } = useParams(); // Extract chatbotId from URL parameters

  useEffect(() => {
    if (Array.isArray(details)) {
      const channels = details
        .filter(detail => detail.type == 'facebook')
        .map(detail => ({
          chatbot_id: detail.chatbot_id || '',
          channel_id: detail.channel_id,
          facebookPageName: detail.facebook_page_name || '',
          facebookPageID: detail.facebook_page_id || '',
          facebookStatus: detail.facebook_status || '',

        }));

      setChannels(channels);
    }
  }, [details]);

  /*useEffect(() => {
    window.scrollTo(0, 0);

    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '3729084597409698',
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });
      };
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [navigate]);*/

  const handleFacebookLogin = () => {
    setIsLoading(true);

    const fbLogin = () => new Promise((resolve, reject) => {
      window.FB.login(
        (response) => {
          if (response.status === 'connected') {
            const accessToken = response.authResponse.accessToken;
            const token = localStorage.getItem('access_token');
            fetch(`${process.env.REACT_APP_API_URL}/api/updateFBToken`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: token,
              },
              body: JSON.stringify({ accessToken, chatbot_id: id }),
            })
              .then(response => response.json())
              .then(data => {
                if (data.success) {
                  const facebookDetailsArray = data.facebook_details;
                  const channels = facebookDetailsArray.map(facebookDetails => ({
                    chatbot_id: id,
                    channel_id: facebookDetails.facebook_page_id,
                    facebookPageName: facebookDetails.facebook_page_name,
                    facebookPageID: facebookDetails.facebook_page_id,
                    facebookStatus: facebookDetails.facebook_status,
                  }));
                  setChannels(channels);
                  resolve('Facebook login successful!');
                } else {
                  reject(new Error('Failed to update Facebook token'));
                }
              })
              .catch(error => {
                reject(new Error(`Error updating Facebook token: ${error.message}`));
              });
          } else {
            reject(new Error('Facebook login failed'));
          }
        },
        {
          scope: 'pages_messaging,pages_manage_metadata,pages_show_list,business_management',
        }
      );
    });

    toast.promise(
      fbLogin(),
      {
        pending: 'Logging in with Facebook...',
        success: 'Facebook login successful!',
        error: {
          render({ data }) {
            return data.message || 'Error logging in with Facebook';
          }
        },

      },
      { position: "top-center" }
    ).catch(error => {
      console.error(error);
    }).finally(() => {
      setIsLoading(false);
    });
  };


  const handleDelete = async (channel_id) => {
    const token = localStorage.getItem('access_token');
    const deleteChannel = async () => {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/channels/${channel_id}`, {
        headers: {
          Authorization: token,
        },
      });

      setChannels(channels.filter(channel => channel.channel_id !== channel_id));
    };

    toast.promise(
      deleteChannel(),
      {
        pending: 'Deleting channel...',
        success: 'Channel deleted successfully!',
        error: {
          render({ data }) {
            return data.message || 'Error deleting channel';
          }
        }
      },
      { position: "top-center" }

    ).catch(error => {
      console.error('Error deleting channel:', error);
    });
  };


  // Ensure you have included this line in your app's entry point (e.g., App.js or index.js) to use ReactToastify

  const handleConnectDisconnect = async (facebook_page_id, action) => {
    const token = localStorage.getItem('access_token');
    const updateChannelStatus = async () => {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/${action}`,
        {
          page_id: facebook_page_id,
          chatbot_id: id
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // Update the channel status locally
      setChannels(channels.map(channel =>
        channel.facebookPageID === facebook_page_id
          ? { ...channel, facebookStatus: action === 'connect' ? 'Connected' : 'Disconnected' }
          : channel
      ));
    };

    toast.promise(
      updateChannelStatus,
      {
        pending: `Processing ${action}...`,
        success: `${action.charAt(0).toUpperCase() + action.slice(1)}ed successfully!`,
        error: `Error ${action}ing source`
      },
      { position: "top-center" }

    ).catch(error => {
      console.error(`Error ${action}ing source:`, error);
    });
  };


  const renderChannels = () => {
    return channels.map((channel, index) => (
      <ListItem key={index} style={{ display: 'flex', alignItems: 'center' , padding: '0'
      }}>
        <Box width={'40%'} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight:'1rem'}} >
          {channel.facebookPageName && (
            <>
              <FacebookIcon  />
              <ListItemText primary={`${channel.facebookPageName} `} sx={{padding: '0'}} /> {/*  Status: ${channel.facebookStatus} */}
            </>
          )}
        </Box>
        <Box width={'60%'}> 
          {channel.facebookStatus === 'Connected' ? (
            <Button variant="contained" color="warning" onClick={() => handleConnectDisconnect(channel.facebookPageID, 'disconnect')} style={{ marginLeft: '10px' , width: '8rem'}}>Disconnect</Button>
          ) : (
            <Button variant="contained" onClick={() => handleConnectDisconnect(channel.facebookPageID, 'connect')} style={{ marginLeft: '10px' , width: '8rem'}}>Connect</Button>
          )}

          <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleDelete(channel.channel_id)} style={{ marginLeft: '10px' , width: '8rem'}}>Delete</Button>
        </Box>
      </ListItem>
    ));
  };

  return (

           <>
              <List sx={{ paddingTop: '0', paddingBottom: '0' }}>
                {renderChannels()}
              </List>
              {channels.length === 0 && (
                  <>
                    <Button variant="contained"
                      onClick={handleFacebookLogin}
                    >
                      Log in to Facebook
                    </Button>
                    {isLoading && (
                      <img src={loaderGif} alt="Loading" className="loading-gif" />
                    )}
                  </>
              )}
            </>

  );

};

export default Connect;
