import React from 'react';
import {
  Box,
  Button,
  Typography,
  Divider,
  Paper,
  Unstable_Grid2 as Grid
} from '@mui/material';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleCalendar from '../images/googlecalendar.png';
import MicrosoftCalendar from '../images/outlook.webp';
import ConnectGoogleCalendar from './ConnectGoogleCalendar';
import ConnectMicrosoftCalendar from './ConnectMicrosoftCalendar';

const ChannelsTabBooking = ({ details }) => {

  const { id } = useParams();
  const accountId = details.account_id;
  const locationId = details.location_id;
  const themeBackgroundColor = details.style.ThemeBackgroundColor;

  // Function to copy the embed code
  const handleCopyCode = () => {
    const code = `<script>
  window.embeddedBookingConfig = {
    accountId: "${accountId}",
    locationId: "${locationId}",
    themeBackgroundColor: "${themeBackgroundColor}" }
  </script>
  <script
  src="https://www.aissie.com.au/booking-bundle.js"
  accountId="${accountId}"
  locationId="${locationId}"
  themeBackgroundColor="${themeBackgroundColor}"
  defer>
  </script>
  <div id="aissie-booking"></div>`;
    navigator.clipboard.writeText(code);
    toast.success('Copied successfully!', {
      position: 'top-center',
    });
  };

  return (
    <Box sx={{ padding: { xs: '1rem', sm: '2rem' } }}>
      <Typography variant="h4" fontWeight="700" padding="1rem 0">
        Add to Website
      </Typography>
      <Paper square={false} elevation={4} sx={{ width: '100%', padding: '1rem', boxSizing: 'border-box' }}>
        <Box mt={2}>
          <Typography variant="h6" gutterBottom fontWeight="700">
            Add booking to your website
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Copy and paste the following script into your website to embed the booking, right before
            the closing body tag.
          </Typography>
          <Box mb={2} p={2} bgcolor="#f2f2f2" borderRadius={4} sx={{ overflowX: 'auto', mt: 1 }}>
            <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
              {`<script>
  window.embeddedBookingConfig = {
    accountId: "${accountId}",
    locationId: "${locationId}",
    themeBackgroundColor: "${themeBackgroundColor}" }
  </script>
  <script
  src="https://www.aissie.com.au/booking-bundle.js"
  accountId="${accountId}"
  locationId="${locationId}"
  themeBackgroundColor="${themeBackgroundColor}"
  defer>
  </script>
  <div id="aissie-booking"></div>`}
            </pre>
            <Box mt={1} display="flex" justifyContent="flex-end">
              <Button variant="contained" onClick={handleCopyCode}>
                Copy Code
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box display={"flex"} alignItems={"center"}>
          <Grid>
            <Typography variant="h6" gutterBottom fontWeight="700" mt={2}>
              Connect your Google Calendar
            </Typography>
            <Typography variant="body2" color="textSecondary">
              You can link the booking calendar with Google Calendar. This will allow the AI assistant to check your availability and book appointments on your behalf.
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                {/* Google Calendar Connection */}
                <Grid item xs={12} sm={6} md={4}>
                  <Paper square={false} elevation={4} sx={{ padding: '1rem', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {/* Optionally, you can include a Google Calendar logo */}
                      <img
                        src={GoogleCalendar}
                        alt="Google Calendar"
                        className="hero-image-connect"
                        style={{ height: 'auto', marginBottom: '1rem' }}
                      />
                      <ConnectGoogleCalendar />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid>
            <Typography variant="h6" gutterBottom fontWeight="700" mt={2}>
              Connect your Microsoft Calendar
            </Typography>
            <Typography variant="body2" color="textSecondary">
              You can link the booking calendar with Microsoft Calendar. This will allow the AI assistant to check your availability and book appointments on your behalf.
            </Typography>
            <Box mt={2}>
              <Grid container spacing={2}>
                {/* Microsoft Calendar Connection */}
                <Grid item xs={12} sm={6} md={4}>
                  <Paper square={false} elevation={4} sx={{ padding: '1rem', height: '100%' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {/* Optionally, you can include a Microsoft Calendar logo */}
                      <img
                        src={MicrosoftCalendar}
                        alt="Microsoft Calendar"
                        className="hero-image-connect"
                        style={{ height: 'auto', marginBottom: '1rem' }}
                      />
                      <ConnectMicrosoftCalendar />
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
        <Divider sx={{ mt: 2 }} />
      </Paper>
    </Box>
  );
};

export default ChannelsTabBooking;
